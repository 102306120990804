<body data-spy="scroll" data-target=".fixed-top">
  <div class="container">
    <div class="session">
      <div class="left">
      </div>
      <form [formGroup]="signUpForm" (ngSubmit)="submit(signUpForm)" class="log-in" autocomplete="off">
        <div class="main_head">
          <h2>SIGNUP</h2>
        </div>
        <div class="floating-label">
          <input class="form-control" placeholder="Name" formControlName="name" name="firstname" type="text" id="name"
            autocomplete="off"
            [ngClass]="{ 'is-invalid': submitted && f.name.errors, '': f.name.touched && !f.name.errors }">
          <label for="firstname">Name</label>
          <div class="icon">
            <div class="icon icon-tree">
              <i class="fa fa-user"></i>
            </div>
          </div>
        </div>
        <!--<div class="floating-label">-->
        <!--<input class="form-control" placeholder="Last Name" type="text"  name="lastname" formControlName="lastname" id="lastname" autocomplete="off"-->
        <!--[ngClass]="{ 'is-invalid': submitted && f.lastname.errors, '': f.lastname.touched && !f.lastname.errors }">-->
        <!--<label for="lastname">Last Name</label>-->
        <!--<div class="icon">-->
        <!--<div class="icon icon-tree">-->
        <!--<i class="fa fa-user"></i>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="floating-label">-->
        <!--<input class="form-control" placeholder="Username" type="text" name="username" formControlName="username" id="username" autocomplete="off"-->
        <!--[ngClass]="{ 'is-invalid': submitted && f.username.errors, '': f.username.touched && !f.username.errors }"-->
        <!--&gt;-->
        <!--<label for="username">Username</label>-->
        <!--<div class="icon">-->
        <!--<div class="icon icon-tree">-->
        <!--<i class="fa fa-user"></i>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <div class="floating-label">
          <input class="form-control" placeholder="Email" type="email" name="email" formControlName="email" id="email"
            autocomplete="off"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors, '': f.email.touched && !f.email.errors }">
          <label for="email">Email</label>
          <div class="icon">
            <div class="icon icon-tree">
              <i class="fa fa-envelope"></i>
            </div>
          </div>
        </div>
        <div class="floating-label">
          <input class="form-control" placeholder="Password" [type]="hide ? 'password' : 'text'"
            formControlName="password" name="password" id="password" (ngModelChange)="valueChange($event)"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors, '': f.password.touched && !f.password.errors }"
            autocomplete="off">
          <i [class]="hide ? 'fa fa-eye-slash' : 'fa fa-eye'" (click)="showPassword()" style="position: absolute;
    right: 10px;
    top: 15px;
    font-size: 25px;"></i>
          <label for="password">Password</label>
          <div class="icon">
            <div class="icon icon-tree">
              <i class="fa fa-lock"></i>
            </div>
          </div>

        </div>

        <div class="floating-label">
          <input class="form-control" placeholder="Confirm Password" [type]="hide ? 'password' : 'text'"
            formControlName="c_password" name="c_password" (ngModelChange)="valueChange($event)"
            [ngClass]="{ 'is-invalid': submitted && f.c_password.errors, '': f.c_password.touched && !f.c_password.errors }"
            id="c_password" autocomplete="off">
          <label for="c_password">Confirm Password</label>
          <div class="icon">
            <div class="icon icon-tree">
              <i class="fa fa-lock"></i>
            </div>
          </div>

          <div class="text-red" style="color: #d22346;font-size: smaller;margin-left: 45px;">
            <div *ngIf="passMatchCheck === false">Passwords must match</div>
          </div>

        </div>
        <!--<div class="floating-label">-->
        <!--<input placeholder="Country" type="text" autocomplete="off">-->
        <!--<label for="password">Country:</label>-->
        <!--<div class="icon">-->

        <!--</div>-->

        <!--</div>-->

        <div class="flex-parent">
          <div class="flex-item">
            <div routerLink="/auth/login" class="discrete" style="cursor: pointer;">
              Already Member</div>

          </div>
          <div class="flex-item">
            <label class="check-container">
              <input style="width: 50px;" type="checkbox" (change)="onCheckboxChange($event)" />
              <a (click)="openModal(template)">Terms & Conditions</a>
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="flex-item" style="text-align: right;
    margin-right: 25px;">
            <button *ngIf="!isLoader" [ngClass]="{'cursor-disable': btnDisable === true}" [disabled]="btnDisable"
              type="submit" style="margin-right: -50px;">Sign Up</button>
            <button *ngIf="isLoader" [disabled]="isLoading"
              style="margin-right: -50px; cursor: not-allowed !important;"><i class="fa "
                [ngClass]="{'fa-spin fa-spinner': isLoader, '': !isLoader}"></i>Sign Up</button>

          </div>
        </div>

      </form>
    </div>
  </div>
</body>

<ng-template  #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Terms & Conditions</h4>
    <button style="all: unset;" type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p [innerHTML]="data"></p>
    <button (click)="modalRef?.hide()">Close</button>
  </div>
</ng-template>