import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TribesService} from '../../shared/services/tribes.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-add-tribe-user',
  templateUrl: './add-tribe-user.component.html',
  styleUrls: ['./add-tribe-user.component.css', '../../../assets/css/styles.css']
})
export class AddTribeUserComponent implements OnInit {
  public mediaPath = environment.mediaPath;
  public tribeId: number;
  public tribeUsers: any = [];
  constructor(private activeRoute: ActivatedRoute, private tribeService: TribesService) { }

  ngOnInit(): void {
    this.getQueryParam();
    if (this.tribeId){
      this.fetchTribeUsers(this.tribeId);
    }
  }
  getQueryParam(): void {
    this.activeRoute.params.subscribe(params => {
      this.tribeId = params.id;
    });
  }

  fetchTribeUsers(tribe_id: number): void{
    this.tribeService.getUserToAddTribe(tribe_id).subscribe(results => {
      const {data} = results;
      this.tribeUsers = data;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  addUser(id: number): void{
    const params = {tribe_id: this.tribeId, user_id: id};
    this.tribeService.addUserToTribe(params).subscribe(results => {
      const {code} = results;
      if (code === '200'){
        this.fetchTribeUsers(this.tribeId);
      }
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

}
