<main>
  <section class="tribes" style="overflow-x: hidden">
    <div class="form-group" style="padding-top: 50px;">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-xs-12" style="margin-left: 25%;margin-right: 25%;">
          <label for="tribe_id">
            <h3 class="m-0">Please select tribe for discussion</h3>
          </label>
          <div class="group-gap">
            <div class="d-block my-3">
              <div class="mb-3">
                <!--<div id="custom-select">-->
                <select id="mySelect" class="form-control" [(ngModel)]="selectedTribe" (change)="viewArticles()">
                  <option value="undefined" selected>Please select tribe</option>
                  <option *ngFor="let item of tribesList" value="{{item.tribe_id}}">{{item.title}}</option>
                </select>
                <!--</div>-->

                <!-- error block -->
                <!--<div class="invalid-feedback" *ngIf="submitted && f.tribe_id.errors">-->
                <!--<sup>*</sup>Please select tribe-->
                <!--</div>-->
              </div>
            </div>
          </div>
          <!--<input type="text" class="form-control" name="article_title" id="tribe_id" placeholder="Title" formControlName="article_title"-->
          <!--[ngClass]="{ 'is-invalid': submitted && f.article_title.errors, '': f.article_title.touched && !f.article_title.errors }">-->
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12"></div>
      </div>
    </div>
    <div class="row" style="padding-top: 40px;" id="articleSection">
      <!--<div class="col-lg-3">-->
      <!--<h4 style="margin-left: 57px">Choose Tribe</h4>-->
      <!--<ul class="with-scroll">-->
      <!--<ng-container *ngFor="let item of tribesList; let i = index">-->
      <!--<div class="tribe-box box shadow1" (click)="viewArticles(item.tribe_id, 'articleSection')">-->
      <!--<div class="inner">-->
      <!--<h3 style="cursor: pointer">{{item.title}}</h3>-->
      <!--<p>Articles</p>-->
      <!--</div>-->
      <!--<div class="icon">-->
      <!--<i class="fa fa-users"></i>-->
      <!--</div>-->
      <!--<a class="tribe-box-footer" style="cursor: pointer">-->
      <!--View Articles <i class="fa fa-arrow-circle-right"></i>-->
      <!--</a>-->
      <!--</div>-->
      <!--</ng-container>-->
      <!--</ul>-->

      <!--</div>-->

      <div class="col-lg-12 col-md-12 col-xs-12">
        <ng-container *ngFor="let item of articleList">
          <div class="container">
            <div class="tribes_head">
              <h1>{{item.article_title}}</h1>
            </div>
            <div class="row">
              <div class="col-md-7">
                <div class="img_detail">
                  <img src="{{item.image.replace('/public', '')}}" alt="" class="first_img">
                  <p class="comments" (click)="toggleComments(item)">
                    <a class="comment"><i class="fa fa-comments mr-1"></i>comments</a>
                  </p>
                  <div class="comment_show" *ngIf="display">
                    <ng-container *ngIf="item.id === articleId">
                      <div class="all_cmnt">
                        <ul class="do_cmnt read_cmnt" *ngFor="let comment of articleComments">
                          <span style="display: flex;">
                            <img *ngIf="comment.parent_comment?.image"
                              src="{{mediaPath}}{{comment.parent_comment?.image}}" alt="" title="user">
                            <img *ngIf="!comment.parent_comment?.image" src="assets/images/old.png" alt="" title="user">

                            <li><span style="font-weight: bold">{{comment.parent_comment?.name}}</span><br>
                              <span [innerHTML]="comment.parent_comment?.comment"> </span>
                            </li>
                          </span>
                          <!--<i class="fa fa-thumbs-up" aria-hidden="true"></i>-->
                          <div class="row col-12" style="margin-left: 30px">
                            <a *ngIf="comment.parent_comment.is_current_user_liked === false"
                              (click)="postLike(comment.parent_comment.id)"
                              style="margin-left: 5px; cursor: pointer">Like</a>
                            <a *ngIf="comment.parent_comment.is_current_user_liked === true"
                              (click)="postLike(comment.parent_comment.id)"
                              style="margin-left: 5px; cursor: pointer; color: #2a8bf2; font-weight: bold">Like</a>
                            <a (click)="postChildComment(comment.parent_comment)"
                              style="margin-left: 10px; cursor: pointer">Reply</a>
                            <span> - {{getMinimalisticRelativeTime(comment.parent_comment?.created_at)}}</span>
                            <ng-container *ngIf="user.can_delete === '1'">
                              <a (click)="deleteComment(comment.parent_comment.id)"
                                style="margin-left: 10px; cursor: pointer">Delete</a>
                            </ng-container>

                            <i class="fa fa-thumbs-up like-thumb" aria-hidden="true"></i><span
                              class="likes-count">{{comment.parent_comment.total_likes}}</span>
                          </div>

                          <ng-container *ngIf="comment.parent_comment.child.length > 0">
                            <ng-container *ngIf="isViewReply">
                              <span class="comment-reply" (click)="viewComments(comment.parent_comment.id)">Show more
                                comments</span>
                            </ng-container>
                            <!--<ng-container *ngIf="isCommentHide">-->
                            <!--<span class="comment-reply" (click)="hideComment(comment.parent_comment.id)">Hide Reply</span>-->
                            <!--</ng-container>-->

                          </ng-container>
                          <div class="comment_section"
                            *ngIf="item.is_joined === 'yes' && isParentId === comment.parent_comment.id">
                            <img *ngIf="displayChild" src="{{authService.getUserImage()}}" alt="">
                            <div class="reply">
                            <textarea *ngIf="displayChild" rows="1" type="text" class="form-control" placeholder="Write a reply.."
                              [(ngModel)]="childCommentText" (keyup.enter)="submitComment('child')"></textarea>
                              <span *ngIf="displayChild">{{childCommentText ? childCommentText?.length : 0}}/500</span>
                            </div>
                          </div>
                          <ng-container *ngIf="isCommentShow && pCommentId === comment.parent_comment.id">
                            <ng-container *ngIf="comment.parent_comment.child.length > 0">
                              <ul *ngFor="let child of comment.parent_comment?.child">
                                <span style="display: flex">
                                  <img *ngIf="child?.image" src="{{mediaPath}}{{child?.image}}" alt="" title="user">
                                  <img *ngIf="!child?.image" src="assets/images/old.png" alt="" title="user">
                                  <li><span style="font-weight: bold">{{child?.name}}</span><br><span
                                      [innerHTML]="child?.comment" style="margin-left: 10px"></span></li>
                                </span>
                                <div class="row col-12" style="margin-left: 30px">
                                  <a *ngIf="child.is_current_user_liked === false" (click)="postLike(child.id)"
                                    style="margin-left: 10px; cursor: pointer">Like</a>
                                  <a *ngIf="child.is_current_user_liked === true" (click)="postLike(child.id)"
                                    style="margin-left: 10px; cursor: pointer; color: #2a8bf2; font-weight: bold">Like</a>
                                  <span style="margin-left: 5px"> -
                                    {{getMinimalisticRelativeTime(child?.created_at)}}</span>
                                  <ng-container *ngIf="user.can_delete === '1'">
                                    <a (click)="deleteComment(child.id)"
                                      style="margin-left: 10px; cursor: pointer">Delete</a>
                                  </ng-container>
                                  <i class="fa fa-thumbs-up like-thumb" aria-hidden="true"></i><span
                                    class="likes-count">{{child.total_likes}}</span>
                                </div>
                              </ul>
                            </ng-container>
                          </ng-container>
                        </ul>
                      </div>
                      <div class="prvs" *ngIf="item.is_joined === 'yes'">
                        <!--<a>Load More</a>-->
                      </div>
                      <div class="comment_section" *ngIf="item.is_joined === 'yes'">
                        <img src="{{authService.getUserImage()}}" alt="">
                        <textarea maxlength="500" type="text" class="form-control" placeholder="Write a comment.."
                          [(ngModel)]="commentText" (keyup.enter)="submitComment('parent')"></textarea>
                      </div>
                      <div class="d-flex justify-content-end">
                        <span>{{commentText?.length}}/500</span>
                      </div>


                      <div class="comment_section" *ngIf="item.is_joined === 'no'">
                        <button class="btn btn-primary" (click)="checkUserForJoin(item)">Please join this tribe to add
                          comments</button>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="details_section">
                  <p [innerHTML]="item.description"></p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="item-list no-items-found" style="margin-left: 25%;" *ngIf="articleList.length === 0">
          <span style="font-size: 30px">No Article Found</span>
        </div>
      </div>
    </div>

  </section>
</main>

<div bsModal #submitModal="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{'backdrop':'static', 'keyboard': false}">

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <span class="text-center">
          <h2 style="margin-top: 77px;font-size: 22px; color: #3D3D3D">Are you sure want to join this <span
              *ngIf="tribeName" style="font-weight: 400">{{tribeName}}</span> tribe?</h2>
        </span>
        <div class="justify-content-center" style="margin-top: 50px;margin-bottom: 20px">
          <button class="btn btn-danger" type="button" (click)="option1Chosen()" data-dismiss="modal"
            style="width: 30%;margin-right: 20px">Cancel</button>
          <button class="btn btn-success" type="button" (click)="joinTribe()" data-dismiss="modal"
            style="width: 30%">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>