<body data-spy="scroll" data-target=".fixed-top">

<div class="container">

  <div class="session">
    <div class="left">

    </div>
    <form [formGroup]="forgotForm" (ngSubmit)="submit(forgotForm)" class="log-in" autocomplete="off">
      <div class="main_head">
        <h2>FORGOT PASSWORD</h2>
      </div>
      <div class="floating-label">
        <input class="form-control" placeholder="Email" type="email" name="email" formControlName="email" id="email" autocomplete="off"
               [ngClass]="{ 'is-invalid': submitted && f.email.errors, '': f.email.touched && !f.email.errors }"
        >
        <label for="email">Email:</label>
        <div class="icon">
          <div class="icon icon-tree">
            <i class="fa fa-envelope"></i>
          </div>
        </div>
      </div>
      <button type="submit">Send</button>
    </form>
  </div>
</div>
</body>
