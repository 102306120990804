import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TribesService } from 'src/app/shared/services/tribes.service';

@Component({
  selector: 'app-tribes-requests',
  templateUrl: './tribes-requests.component.html',
  styleUrls: ['./tribes-requests.component.css']
})
export class TribesRequestsComponent implements OnInit {

  @ViewChild('submitModal') binaryModal: ModalDirective;

  public tribesList: any = [];
  public requestJoinTribesList: any = [];
  public id: number;
  public user: any;
  public joinStatus: string;
  public tribeId: number;
  public isJoined: boolean;
  public tribeDetail: any;
  public tribeUsers: any = [];
  public userId: number;

  constructor(
    private tribesService: TribesService, private authService: AuthService,
     private router: Router, private toast: ToastrService,
     private route: ActivatedRoute,
     private tribeService: TribesService
  ) {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getQueryParam();
   }


  ngOnInit(): void {
    this.fetchJoinRequestTribe(this.user.id);
  }


  getQueryParam(): void {
    this.route.params.subscribe(params => {
      this.tribeId = params.id;
    });
  }

  fetchJoinRequestTribe(leader_id: number): void{
    this.tribesService.getJoinTribesRequests(leader_id).subscribe(results => {
      // const data['articles'] = results.data;
      this.requestJoinTribesList = results.data;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }



  acceptJoinRequest(item): void{
    if (this.authService.isAuthenticated()){
      this.submitRequestJoinTribe(item.user_id, item.tribe_id);
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  submitRequestJoinTribe(userId: number, tribeId: number): void{
    const params = {tribe_id: tribeId, user_id: userId};
    this.tribesService.joinTribe(params).subscribe(results => {
      this.fetchJoinRequestTribe(this.user.id);
      this.toast.success(results.message);
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }


  fetchTribesTopics(id: number): void{
    this.tribesService.getTribesTopics(id).subscribe(results => {
      this.requestJoinTribesList = results.data;
      this.tribeDetail = results.tribe_details[0];
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }


  fetchTribeUsers(tribe_id: number): void{
    this.tribeService.getTribeUsers(tribe_id).subscribe(results => {
      const {data} = results;
      this.tribeUsers = data;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }


  declineJoinRequest(): void{
    const params = {tribe_id: this.tribeId, user_id: this.userId};
    this.tribeService.declineJoineTribeRequest(params).subscribe(results => {
      const {status} = results;
      const {message} = results;
      if (status === 'success'){
        this.fetchJoinRequestTribe(this.user.id);
        this.binaryModal.hide();
        this.toast.success(message);
      }
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  option1Chosen(): void{
    this.binaryModal.hide();
  }

  showModal(id: number, tribeId: number){
    if (id){
      this.binaryModal.show();
      this.userId = id;
      this.tribeId = tribeId;
    }
  }

}
