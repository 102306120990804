import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from './shared/services/auth.service';
const MINUTES_UNTIL_AUTO_LOGOUT = 15; // in mins
const CHECK_INTERVAL = 2000; // in ms
const STORE_KEY = 'lastAction';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'scorai';
  constructor(private router: Router, public authService: AuthService, private toast: ToastrService) {
    this.check();
    this.initListener();
    this.initInterval();
  }
  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  initListener(): void {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset(): void {
    this.setLastAction(Date.now());
  }

  initInterval(): void {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check(): void {
    
    const now = Date.now();
    const user = JSON.parse(localStorage.getItem('user'));
    const timeLeft = this.getLastAction() + MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeLeft - now;
    const isTimeout = diff < 0;
    if (isTimeout && user) {
      this.logout();
    }
  }

  logout(): void {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    const body = {user_id: user.id, access_token: token};
    this.authService.logoutUser(body).subscribe((data) => {
      this.authService.logout();
    }, error => {
      this.toast.error(error.error.error_description);
    });

  }

}
