import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment";
import {DatePipe} from '@angular/common';

@Pipe({name: 'baDate',
})
export class BaDate implements PipeTransform {
  transform(input?: string, from?: string, to?: string): any {
    // return moment(input, from).format(to);
    if (!input) {
      return '';
    }
    return moment(input).format(to);
  }

}
