import { Component, OnInit } from '@angular/core';
import {QuestionsService} from '../../shared/services/questions.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-pass-levels',
  templateUrl: './pass-levels.component.html',
  styleUrls: ['./pass-levels.component.css', '../../../assets/css/styles.css']
})
export class PassLevelsComponent implements OnInit {
  public user: any = [];
  public levelData: any = [];
  constructor(private questionService: QuestionsService, private toast: ToastrService) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit(): void {
    this.fetchLevels();
  }

  fetchLevels(): void{
    this.questionService.getLevels().subscribe(results => {
      this.levelData = results.data;
      console.log(this.levelData);
      // this.totalAskedQuestions = results.total_asked_questions_by_user;
      // this.totalEarnedPoints = results.toal_points_earned_by_user;
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }
  // https://app.socrai.com/api/get_level_crossed/{user_id}
}
