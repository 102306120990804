<body data-spy="scroll" data-target=".fixed-top">

<div class="container">

  <div class="session">
    <div class="left">

    </div>
    <form [formGroup]="verificationForm" (ngSubmit)="submit(verificationForm)" class="log-in" autocomplete="off">
      <div class="main_head">
        <h4 style="color: #2a8bf2">Please check you email for verification code</h4>
      </div>
      <div class="floating-label">
        <input class="form-control" placeholder="Verification Code" type="number" name="email" formControlName="code" id="code" autocomplete="off"
               [ngClass]="{ 'is-invalid': submitted && f.code.errors, '': f.code.touched && !f.code.errors }"
        >
        <label for="code">Verification Code:</label>
        <div class="icon">
          <div class="icon icon-tree">
            <i class="fa fa-lock"></i>
          </div>
        </div>
      </div>
      <div class="row col-12 mt-3">
        <div class="col-3">
          <div (click)="verificationEmail()" class="discrete" style="margin-top: 20px;position: absolute;cursor: pointer;text-decoration: underline">Resend email</div>
        </div>
        <div class="col-5"></div>
        <div class="col-4 text-right">
          <button type="submit" style="margin-right: -50px;">Submit</button>
        </div>
      </div>
      <!--<button type="submit">Send</button>-->
      <!--<span style="text-decoration: underline">Resend email</span>-->
    </form>
  </div>
</div>
</body>
