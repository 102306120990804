import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TribesDetailRoutingModule} from './tribes-detail-routing.module';
import {FormsModule} from '@angular/forms';
import {TribesDetailComponent} from './tribes-detail.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import { SafePipe } from 'src/app/shared/pipes/safepipe';

@NgModule({
  imports: [
    CommonModule,
    TribesDetailRoutingModule,
    FormsModule,
    ModalModule.forRoot()
  ],
  declarations: [TribesDetailComponent, SafePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TribesDetailModule { }
