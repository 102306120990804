
<div class="right_section">
<div class="row">
  <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
    <div class="text-center mt-3" onclick="document.getElementById('imageThumbLoad').click()">
      <img class="image-uploaded" *ngIf="imageSrc" [src]="imageSrc"/>
      <img src="assets/images/avatar_2x.png" *ngIf="!imageSrc" class="avatar img-circle img-thumbnail" alt="avatar"/>
      <input hidden (change)="handleFileInput($event, $event.target.files)" type="file" (change)="handleInputChange($event)"
             class="form-control" id="imageThumbLoad" name="imageThumbLoad" placeholder="Title" accept="image/*"
             >
      <label class="alert-message" *ngIf="errorMessage">{{errorMessage}}</label>
      <h6>Upload Image</h6>

    </div>
    <i *ngIf="imageSrc" class="fa fa-trash" style="font-size: 25px; top: 215px; position: absolute; right: 40px; cursor: pointer" (click)="removeImage()"></i>

  </div>
  <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
    <div class="tab-content">
      <div class="tab-pane active" id="home">
        <hr>
        <form class="form" action="##" method="post" id="registrationForm">
          <div class="form-group">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="first_name"><h6 class="m-0">First name</h6></label>
              <input type="text" class="form-control" [(ngModel)]="user.name" name="first_name" id="first_name" placeholder="first name" title="enter your first name if any.">
            </div>
          </div>
          <!--<div class="form-group">-->

            <!--<div class="col-xs-6">-->
              <!--<label for="last_name"><h6 class="m-0">Last name</h6></label>-->
              <!--<input type="text" class="form-control" name="last_name" id="last_name" placeholder="last name" title="enter your last name if any.">-->
            <!--</div>-->
          <!--</div>-->

          <!--<div class="form-group">-->

            <!--<div class="col-xs-6">-->
              <!--<label for="name"><h6 class="m-0">Username</h6></label>-->
              <!--<input type="text" class="form-control" [(ngModel)]="user.email" name="phone" id="phone" placeholder="Username" title="enter your phone number if any.">-->
            <!--</div>-->
          <!--</div>-->


          <div class="form-group">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="email"><h6 class="m-0">Email</h6></label>
              <input type="email" class="form-control" [disabled]="true" [(ngModel)]="user.email" name="email" id="email" placeholder="you@email.com" title="enter your email.">
            </div>
          </div>

          <div class="form-group">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="password"><h6 class="m-0">Password</h6></label>
              <input [type]="hide ? 'password' : 'text'" class="form-control" name="password" [(ngModel)]="newPassword"
                     id="password" placeholder="password" title="enter your password.">
              <i [class]="hide ? 'fa fa-eye-slash' : 'fa fa-eye'" (click)="showPassword()" style="position: absolute;
    right: 25px;
    top: 35px;
    font-size: 25px;"></i>
            </div>
          </div>

          <div class="form-group">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label for="password"><h6 class="m-0">Confirm Password</h6></label>
              <input [type]="hide ? 'password' : 'text'" class="form-control" name="password" id="confirmPassword" (keyup)="checkPassword($event)" [(ngModel)]="confirmPassword" placeholder="confirm password" title="Re-enter your password.">
              <div *ngIf="passwordCheck" class='error'>Password does not match</div>
            </div>
          </div>

          <div class="form-group">
            <div class="col-lg-12 col-sm-12">
              <br>
              <button class="btn btn-lg btn-success" type="submit" (click)="updateProfile()" style="font-size: 16px"><i class="glyphicon glyphicon-ok-sign"></i> Update</button>
            </div>
          </div>
        </form>

        <hr>

      </div>



    </div>
  </div>
</div>
</div>
