import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import {AdminFooterComponent} from "./adminfooter/footer.component";
import {HeaderComponent} from "./header/header.component";
import {ModalModule} from 'ngx-bootstrap/modal';
import {ChatModule} from './chat/chat.module';


@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        NotificationSidebarComponent,
        AdminFooterComponent,
        HeaderComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        ChatModule,
        ModalModule.forRoot()
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        NotificationSidebarComponent,
        AdminFooterComponent,
        HeaderComponent
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [

  ]

})
export class SharedModule { }
