<div class="right_section">
  <div class="questions" style="overflow: auto;">
    <div>
      <h3>My Questions</h3>
    </div>
    <div class="row mb-2">
      <div class="col-lg-3" *ngIf="totalAskedQuestions">
        <span>Total Asked Questions:</span><span style="font-weight: bold">{{totalAskedQuestions}}</span>
      </div>
      <div class="col-lg-3" *ngIf="totalEarnedPoints">
        <span>Total Earned Points:</span><span style="font-weight: bold">{{totalEarnedPoints}}</span>
      </div>
    </div>
    <div class="form-group">
<div class="row">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <label for="tribe_id"><h6 class="m-0">Please Select Topic</h6></label>
        <div class="group-gap">
          <div class="d-block my-3">
            <div class="mb-3">
              <!--<div id="custom-select">-->
              <select id="mySelect" class="form-control" [(ngModel)]="selectedTopic" (change)="getQuestions()">
                <option value="undefined" selected>Please Select topic</option>
                <option *ngFor="let item of topicsList" value="{{item.id}}">{{item.title}}</option>
              </select>
              <!--</div>-->

              <!-- error block -->
              <!--<div class="invalid-feedback" *ngIf="submitted && f.tribe_id.errors">-->
                <!--<sup>*</sup>Please select tribe-->
              <!--</div>-->
            </div>
          </div>
        </div>
        <!--<input type="text" class="form-control" name="article_title" id="tribe_id" placeholder="Title" formControlName="article_title"-->
        <!--[ngClass]="{ 'is-invalid': submitted && f.article_title.errors, '': f.article_title.touched && !f.article_title.errors }">-->
      </div>
  <div class="col-lg-6 col-md-6 col-xs-12"></div>
  </div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-3" *ngIf="pointCurrentTopic">
        <span>Current Topic Points:</span><span style="font-weight: bold">{{pointCurrentTopic}}</span>
      </div>
      <div class="col-lg-3" *ngIf="questionAskCurrentTopic">
        <span>Question Asked:</span><span style="font-weight: bold">{{questionAskCurrentTopic}}</span>
      </div>
      <div class="col-lg-3" *ngIf="totalAnswerExerics">
        <span>Total Answers:</span><span style="font-weight: bold">{{totalAnswerExerics}}</span>
      </div>
    </div>
    <table class="table table-bordered table-hover custab q-table">
      <thead>
      <tr>
        <th>Topic</th>
        <th>Question</th>
        <th>Answer</th>
        <th>Type</th>
        <th>Points</th>
        <th>Exercise unlock</th>
        <th>Exercise question true</th>
      </tr>
      </thead>

      <ng-container *ngIf="questions && questions.length > 0">
        <ng-container *ngFor="let item of questions; let i = index">

        <tr *ngFor="let Q of item.questions_answers_at_this_topic">
          <td>{{item.topic_name}}</td>
          <td [innerHTML]="Q.question"></td>
          <td [innerHTML]="Q.answer"></td>
          <td *ngIf="Q.type === 1">Exercise</td>
          <td *ngIf="Q.type === 0">Question</td>
          <td>{{Q.earned_points}}</td>
          <td>{{Q.exercise_unlocked}}</td>
          <td>{{Q.exercise_question_true}}</td>
        </tr>
        </ng-container>

      </ng-container>

      <div class="item-list no-items-found" *ngIf="questions.length === 0">
        <span>No Item Found</span>
      </div>

    </table>
  </div>

</div><!--/col-3-->
