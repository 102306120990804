import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {ArticleService} from '../../shared/services/article.service';
import {NoWhiteSpaceValidator} from '../../shared/custom-validator/noWhitespace.validator';
import {TribesService} from '../../shared/services/tribes.service';
// import {Select2OptionData} from 'ng-select2';

@Component({
  selector: 'app-add-article',
  templateUrl: './add-article.component.html',
  styleUrls: ['./add-article.component.css']
})
export class AddArticleComponent implements OnInit {
  public articleForm: FormGroup;
  public submitted = false;
  public imageSrc: any;
  public errorMessage: string = '';
  public imgType: any;
  public removeType: any;
  public imageThumbLoad;
  public user: any = [];
  fileToUpload: File = null;
  thumbImageDetail: string;
  isImage: boolean;
  public userRole: any = [];
  public tribesList: any = [];
  public articleId: any;
  constructor(private fb: FormBuilder, private toast: ToastrService, private  router: Router,
              private articleService: ArticleService, private tribesService: TribesService,
              private activeRoute: ActivatedRoute) {
    this.getQueryParam();
    this.user = JSON.parse(localStorage.getItem('user'));

    this.userRole = JSON.parse(localStorage.getItem('userRole'));
    if (this.userRole.role !== 2 && this.userRole.is_leader !== 1){
      this.router.navigate(['/user/dashboard']);
    }
  }

  ngOnInit(): void {
    this.loadForm();
    this.fetchTribes();
    if (this.articleId){
      this.fetchArticleById(this.articleId);
    }
  }

  getQueryParam(): void {
    this.activeRoute.params.subscribe(params => {
      this.articleId = params.id;
    });
  }

  fetchArticleById(id: number): void{
    this.articleService.getArticleById(id).subscribe(results => {
      const {data} = results;
      if (data){
        this.articleForm.controls['article_title'].setValue(data.article_title);
        this.articleForm.controls['tribe_id'].setValue(data.tribe_id);
        this.articleForm.controls['description'].setValue(data.description);
        // this.articleForm.controls['image'].setValue(data.image);
        if (data.image){
          this.articleForm.controls['image'].setErrors(null);
        }
      }
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  fetchTribes(): void{
    this.tribesService.getTribes().subscribe(results => {
      this.tribesList = results.success;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  get f() { return this.articleForm.controls; }
  loadForm(): void{
    this.articleForm = this.fb.group({
      article_title: new FormControl('', [
        Validators.required, NoWhiteSpaceValidator.cannotContainSpace
      ]),
      tribe_id: new FormControl('', [
        Validators.required
      ]),
      description: new FormControl('', [
        Validators.required, NoWhiteSpaceValidator.cannotContainSpace, Validators.maxLength(10000), Validators.minLength(3)
      ]),
      image: new FormControl('', [
        Validators.required
      ])
    });
  }

  handleImage = (source, e) => {
    // Cancel
    if (e.target.files.length === 0) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (event: any) => {
      event.preventDefault();

      // Images only
      if (!event.target.result || !event.target.result.startsWith('data:image/')) {
        this.isImage = false;
        this.errorMessage = 'InvalidFile';
        return;
      }
      // this.thumbImageDetail = event.target.result;
      this.fileToUpload = e.target.files[0];
      const img = this.thumbImageDetail.split(',');
      // this.ImagesUploaded.ThumbnailImage = img[1];
      const str = event.target.result.split('/');
      const extension = str[1].split(';');
      const type = extension[0];
      if (type !== 'png' && type !== 'jpg' && type !== 'jpeg' && type !== 'PNG') {
        this.isImage = false;
        this.errorMessage = 'InvalidFile';
        this.thumbImageDetail = '';
        return;
      } else {
        this.isImage = true;
      }
    };
    if (e.target.files[0].size < 3000000) {
      reader.readAsDataURL(e.target.files[0]);
      this.errorMessage = ' ';
    } else {
      this.thumbImageDetail = '';
      this.errorMessage = 'ImageSizeExceeded';
    }

  }


  handleFileInput(event, files: FileList) {
    // Images only
    // const str = files('image/');
    const str = files[0].type.split('image/');
    const type = str[1];
    if (type !== 'png' && type !== 'jpg' && type !== 'jpeg' && type !== 'PNG') {
      this.isImage = false;
      this.errorMessage = 'Invalid File';
      return;
    }
    this.errorMessage = '';
    this.fileToUpload = files.item(0);
    this.articleForm.controls['image'].setValue(this.fileToUpload);
    // this.articleForm.controls.image.setValue(this.fileToUpload);
  }

  submit(form): void{
    this.submitted = true;
    if (this.articleForm.invalid) {
      return;
    }
    this.formData();
    if (this.articleId){
      this.articleService.updateArticle(this.formData()).subscribe(data => {
        if (data.code === '200'){
          this.toast.success('Article Successfully Update', 'Success');
          this.router.navigate(['/user/article-list']);
        }

      }, (error: any) => {
        this.toast.error(error.error.message);
      });
    } else {
      this.articleService.postArticle(this.formData()).subscribe(data => {
        if (data.code === '200'){
          this.toast.success('Article Successfully Created', 'Success');
          this.router.navigate(['/user/article-list']);
        }

      }, (error: any) => {
        this.toast.error(error.error.message);
      });
    }
  }

  formData() {
    const formDataUpload: FormData = new FormData();
    formDataUpload.append('article_title', this.articleForm.controls['article_title'].value);
    formDataUpload.append('description', this.articleForm.controls['description'].value);
    formDataUpload.append('image', this.articleForm.controls['image'].value);
    formDataUpload.append('tribe_id', this.articleForm.controls['tribe_id'].value);
    if (this.articleId){
      formDataUpload.append('article_id', this.articleId);
    }
    return formDataUpload;
  }
}
