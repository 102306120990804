import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css', '../../../assets/css/login.css']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotForm: FormGroup;
  public submitted = false;
  constructor(private fb: FormBuilder, private authService: AuthService, private  router: Router, private toast: ToastrService) { }
  get f() { return this.forgotForm.controls; }
  ngOnInit(): void {
    this.loadBasicInfo();
  }

  loadBasicInfo(): void{
    this.forgotForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')])],
    });
  }

  submit(form): void{
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
    this.authService.forgotPassword(form.value).subscribe(data => {
      if (data.code === '200'){
        this.toast.success(data.message, 'Success');
        this.router.navigate(['/auth/login']);
      }

    }, (error: any) => {
      this.toast.error(error.error.error_description);
    });
  }
}
