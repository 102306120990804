import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Session} from '../models/StorageSessions';
import {HttpService} from './http.service';
import {Router} from '@angular/router';
import { catchError, map } from 'rxjs/internal/operators';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public promise: Promise<boolean>;

	constructor(private http: HttpService, private router: Router, private httpClient: HttpClient) {

  }

  public login(params): Observable<any> {
    const url = 'login';
    return this.http.post<any>(url, params).pipe(
      catchError(error => {
        if (error.status === 401 && error.error.code === '401' && error.error.message === 'Your email address is not verified.') {
          // Redirect to verification code component
          window.localStorage.setItem('verificationEmail', params.email);
          this.router.navigate(['/auth/email-verification']);
        }
        // Re-throw the error for further handling
        return throwError(error);
      })
    );
  }

  public homeContactUs(params): Observable<any> {
    const url = 'contact';
    return this.http.post<any>(url, params)
  }


  public logoutUser(params): Observable<any> {
    const url = 'logout';
    return this.http.post<any>(url, params);
  }


  public register(params): Observable<any> {
    const url = 'register';
    return this.http.post<any>(url, params);
  }

  public emailVerification(email): Observable<any> {
    const url = 'resend_code';
    return this.http.post<any>(url, email);
  }

  public verifyCode(params): Observable<any> {
    const url = 'varify_code';
    return this.http.post<any>(url , params);
  }

  public forgotPassword(params): Observable<any> {
    const url = 'forgot';
    return this.http.post<any>(url , params);
  }

  public getUser(username: string): Observable<any> {
    const url = `/merchant/employee_by_uid?username=${ username }`;
    return this.http.get<any>(url);
  }

  public updateUserProfile(params: any): Observable<any> {
    const url = `profile` ;
    return this.http.post<any>(url, params);
  }

  public deleteProfileImage(param): Observable<any> {
    const url = `remove_user_image`;
    return this.http.post<any>(url, param);
  }

  public getUserProfile(): Observable<any> {
    const url = `profile` ;
    return this.http.get<any>(url);
  }

  public getSessionUser(): string {
    return window.localStorage['user'];
  }

  resetPassword(resetKey, username, password): Observable<any> {
    const url = `/user/resetpassword`;
    return this.http.put<any>(url, {username, password, resetKey});
  }

  public getAccessToken() {
    if (localStorage.getItem('token')) {
      const auth = JSON.parse(localStorage.getItem('token'));
      return auth;
    }
    return null;
  }

  public getUserFullName(): string {
    const user = localStorage.getItem('user');
    if (user){
      return JSON.parse(user).name;
    }
  }

  public getUserImage(): string {
    const user = localStorage.getItem('user');
    if(JSON.parse(user).image) {
      return environment.mediaPath + JSON.parse(user).image;
    } else {
      return 'assets/images/avatar_2x.png'
    }
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  isAuthenticated(): boolean {
    if (localStorage.getItem('user')) {
      return true;
    }
    return false;
  }

  getUsername(): string {
    const user = sessionStorage.getItem(Session.User);
    return JSON.parse(user).username;
  }

  getUserTimeZone(): string {
    const user = sessionStorage.getItem(Session.User);
    return JSON.parse(user).timeZone;
  }

}
