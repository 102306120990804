<div class="right_section">

  <div class="my-members">
    <div>
      <h3>My Topics</h3>
    </div>
    <table class="table all-table table-bordered table-hover custab q-table">
      <thead>

      <tr>
        <th>ID</th>
        <th>Title</th>
        <!--<th>Leader</th>-->
        <th class="text-center">Topics</th>
      </tr>
      </thead>
      <ng-container *ngIf="tribesList && tribesList.length > 0">
        <tr *ngFor="let item of tribesList; let i = index">
          <td>{{i+1}}</td>
          <td>{{item.title}}</td>
          <!--<td>{{item.leader}}</td>-->
          <td class="text-center"><button class="btn btn-success" (click)="getTribeId(item.tribe_id)">Topics</button> </td>
        </tr>
      </ng-container>

      <div class="item-list no-items-found" *ngIf="tribesList.length === 0">
        <span>No Item Found</span>
      </div>

    </table>
  </div>
</div>
