import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent} from './auth.component';
import {AuthRoutingModule} from './auth-routing.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import { VerificationComponent } from './verification/verification.component';


@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    FormsModule

  ],
  declarations: [AuthComponent]
})
export class AuthModule { }
