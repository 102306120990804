import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MyMembersComponent} from './my-members.component';
import {MembersRoutingModule} from './members-routing.module';

@NgModule({
  imports: [
    CommonModule,
    MembersRoutingModule
  ],
  declarations: [MyMembersComponent]
})
export class MembersModule { }
