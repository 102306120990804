import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TribesDetailComponent} from './tribes-detail.component';
import {AuthenticationGuard} from '../../shared/guard/auth.guard';


const routes: Routes = [
  { path: 'tribes-detail/:id', component: TribesDetailComponent, canActivate: [AuthenticationGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TribesDetailRoutingModule { }
