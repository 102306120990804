import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpService} from '../services/http.service';

export class Message {
  constructor(
              public content: string, public sentBy: string, public mediaImage?: string,
              public mediaVideo?: string, public mediaAudio?: string, public clue?: string, public message?: string,
              public level?: string, public mediaType?: string,  public isExercise?: string, public exerciseQuestion?: string,
              public exerciseQuestions?: string, public correctAnswer?: string, public confidenceLevel?: string){

              }
            }

@Injectable({
	providedIn: 'root'
})
export class ChatService {
  conversation = new BehaviorSubject<Message[]>([]);
	constructor(private http: HttpService) {
  }
  update(msg: Message) {
    this.conversation.next([msg]);
  }
  converse(msg: any): Observable<any>{
    const url = `ask/question`;
    return this.http.post<any>(url, msg);
  }
}

