import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ManageTribesRoutingModule} from './managetribes-routing.module';
import {ManageTribesComponent} from './manage-tribes.component';

@NgModule({
  imports: [
    CommonModule,
    ManageTribesRoutingModule
  ],
  declarations: [ManageTribesComponent]
})
export class ManageTribesModule { }
