import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Session} from '../models/StorageSessions';
import {HttpService} from './http.service';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class TribesService {
	public promise: Promise<boolean>;
  public currentTribe = new EventEmitter();
  public tribeEmitted = this.currentTribe.asObservable();
	constructor(private http: HttpService, private router: Router) {

  }

  emitTribe(tribe): void {
    this.currentTribe.emit(tribe);
  }

  public updateUserProfile(params: any): Observable<any> {
    const url = `profile` ;
    return this.http.put<any>(url, params);
  }

  public getJoinedTribes(): Observable<any> {
    const url = `joined/tribes`;
    return this.http.get<any>(url);
  }



  public getTribesTopics(tribe_id: number): Observable<any> {
    const url = `tribe`;
    const body = { tribe_id: tribe_id };
    return this.http.post<any>(url, body);
  }

  public getTopicClues(topic_id: number): Observable<any> {
    const url = `all_clues`;
    const body = { topic_id: topic_id };
    return this.http.post<any>(url, body);
  }

  public getTribes(): Observable<any> {
    const url = `tribes`;
    return this.http.get<any>(url);
  }

  public getUnjoinedTribes(id: number): Observable<any> {
    const url = `tribes_unjoined` ;
    return this.http.get<any>(url);
  }

  public getJoinTribesRequests(leader_id:number): Observable<any> {
    const url = `join_requests`;
    const body = { leader_id: leader_id };
    return this.http.post<any>(url, body);
  }

  public getDiscussionTribes(): Observable<any> {
    const url = `joined/tribes`;
    // const url = `total_joined_tribes/` + id;
    return this.http.get<any>(url);
  }

  public getPrivacyPage(): Observable<any> {
    const url = `privacy_policy`;
    return this.http.get<any>(url);
  }

  public getTermsPage(): Observable<any> {
    const url = `terms_of_use`;
    return this.http.get<any>(url);
  }
  public getManageTribes(): Observable<any> {
    const url = `getLeaderTribes`;
    return this.http.get<any>(url);
  }

  public getTribeUsers(tribe_id): Observable<any> {
    const url = `get_users_of_tribe`;
    const body = { tribe_id: tribe_id };
    return this.http.post<any>(url, body);
  }

  public getUserToAddTribe(tribe_id): Observable<any> {
    const url = `add_user_to_tribe`;
    const body = { tribe_id: tribe_id };
    return this.http.post<any>(url, body);
  }
  public joinTribe(param): Observable<any> {
    const url = `join/tribe`;
    return this.http.post<any>(url, param);
  }

  public requestJoinTribe(param): Observable<any> {
    const url = `request_to_join_tribe`;
    return this.http.post<any>(url, param);
  }

  public unJoinTribe(param): Observable<any> {
    const url = `unjoin/tribe`;
    return this.http.post<any>(url, param);
  }
  public addUserToTribe(param): Observable<any> {
    const url = `add_user_in_tribe`;
    return this.http.post<any>(url, param);
  }

  public deleteUserFromTribe(param): Observable<any> {
    const url = `remove_user_from_tribe`;
    return this.http.post<any>(url, param);
  }

  public declineJoineTribeRequest(param): Observable<any> {
    const url = `decline_request`;
    return this.http.post<any>(url, param)
  }

}
