import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthComponent} from './auth.component';
import {LoginModule} from './login/login.module';
import {RegisterPageModule} from './register/register-page.module';
import {ForgotPasswordModule} from './forgot-password/forgot-password.module';
import {VerificationModule} from './verification/verification.module';


const routes: Routes = [
  {  path: '', component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => LoginModule
      },
      {
        path: '',
        loadChildren: () => RegisterPageModule
      },
      {
        path: '',
        loadChildren: () => ForgotPasswordModule
      },
      {
        path: '',
        loadChildren: () => VerificationModule
      }
    ]},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
