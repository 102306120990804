import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ArticlesRoutingModule} from './articles-routing.module';
import {ArticlesComponent} from './articles.component';
import {FormsModule} from '@angular/forms';
import {ModalModule} from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    ArticlesRoutingModule,
    FormsModule, ModalModule.forRoot()
  ],
  declarations: [ArticlesComponent]
})
export class ArticlesModule { }
