import {Component, OnInit, ViewChild} from '@angular/core';
import {TribesService} from '../../shared/services/tribes.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../shared/services/auth.service';
import {Router} from '@angular/router';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-tribes',
  templateUrl: './join-tribes.component.html',
  styleUrls: ['./join-tribes.component.css', '../../../assets/css/styles.css']
})
export class JoinTribesComponent implements OnInit {
  public id : number;
  active = 1
  public tribesList: any = [];
  public unjoinedTribesList: any = [];
  public user: any = [];
  public tribeId: number;
  constructor(private tribesService: TribesService, private toast: ToastrService,
              private authService: AuthService, private router: Router) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit(): void {
    this.fetchTribes();
    this.fetchUnjoinedTribes()
    
  }


  fetchTribes(): void{
    this.tribesService.getTribes().subscribe(results => {
      this.tribesList = results.success;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  fetchUnjoinedTribes(): void {
   
    this.tribesService.getUnjoinedTribes(this.id).subscribe(results => {
      this.unjoinedTribesList = results.success;
     
    }, error => {

    }
    )
  }

  getTribeId(id: number){
    if (this.authService.isAuthenticated()){
      this.router.navigate(['tribes-detail/' + id]);
    } else {
      this.router.navigate(['/auth/login']);
    }
  }
}
