<div class="right_section">
  <div>
    <h3>Dashboard</h3>
  </div>

  <div id="boxes_dash">
    <div class="row">
      <div class="col-md-3">
        <div class="small-box bg-aqua" routerLink="/tribes" style="cursor: pointer">
          <div class="inner">
            <h3 *ngIf="stats">{{stats.total_tribes}}</h3>
            <p>Total Available Tribes</p>
          </div>
          <div class="icon">
            <i class="fa fa fa-users"></i>
          </div>
          <a class="small-box-footer">
            <!--Correct Answers <i class="fa fa-arrow-circle-right"></i>-->
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="small-box bg-aqua" routerLink="/user/tribes" style="cursor: pointer">
          <div class="inner">
            <h3 *ngIf="stats">{{stats.total_tribes_joined}}</h3>
            <p>Total Joined Tribe</p>
          </div>
          <div class="icon">
            <i class="fa fa fa-users"></i>
          </div>
          <a class="small-box-footer">
            <!--More info <i class="fa fa-arrow-circle-right"></i>-->
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="small-box bg-aqua" routerLink="/user/questions" style="cursor: pointer">
          <div class="inner">
            <h3 *ngIf="stats">{{stats.total_questions}}</h3>
            <p>Total Asked Questions</p>
          </div>
          <div class="icon">
            <i class="fa fa-question-circle"></i>
          </div>
          <a class="small-box-footer">
            <!--More info <i class="fa fa-arrow-circle-right"></i>-->
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="small-box bg-aqua" routerLink="/user/questions" style="cursor: pointer">
          <div class="inner">
            <h3 *ngIf="stats">{{stats.toal_points_earned_by_user}}</h3>
            <p>Total Earned Points</p>
          </div>
          <div class="icon">
            <i class="fa fa-trophy"></i>
          </div>
          <a class="small-box-footer">
            <!--More info <i class="fa fa-arrow-circle-right"></i>-->
          </a>
        </div>
      </div>
    </div>
  </div>
  
<div class="row">
  <div class="col-md-4">
    <div class="main_box">
      <div class="box_icon text-center">
        <img src="assets/images/headphone.png" alt="" class="img-fluid">
        <h4>Learn</h4>
      </div>
      <div class="box_data">
        <ul>
          <li> <i class="fa fa-check"></i> New Learning methodology </li>
          <li> <i class="fa fa-check"></i> Question and exercise-based </li>
          <li> <i class="fa fa-check"></i> Promote tribal and engagement </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="main_box">
      <div class="box_icon text-center">
        <img src="assets/images/head.png" alt="" class="img-fluid">
        <h4>Protect</h4>
      </div>
      <div class="box_data">
        <ul>
          <li> <i class="fa fa-check"></i> Human Expertise </li>
          <li> <i class="fa fa-check"></i> Highly skilled and certified people, </li>
          <li> <i class="fa fa-check"></i> Proven Methodologies </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="main_box">
      <div class="box_icon text-center">
        <img src="assets/images/trouphy.png" alt="" class="img-fluid">
        <h4>Reward</h4>
      </div>
      <div class="box_data">
        <ul>
          <li> <i class="fa fa-check"></i> Change Behaviour through reward </li>
          <li> <i class="fa fa-check"></i> Provide personal meaning </li>
          <li> <i class="fa fa-check"></i> Help Education worlwide </li>
        </ul>
      </div>
    </div>
  </div>
</div>

  <div class="chart">
    <div id="chartContainer" style="height: 300px; width: 100%;"></div>
  </div>
</div>
