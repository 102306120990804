import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-members',
  templateUrl: './my-members.component.html',
  styleUrls: ['./my-members.component.css', '../../../assets/css/styles.css']
})
export class MyMembersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
