import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TribesRoutingModule} from './tribes-routing.module';
import {FormsModule} from '@angular/forms';
import {TribesComponent} from './tribes.component';

@NgModule({
  imports: [
    CommonModule,
    TribesRoutingModule,
    FormsModule
  ],
  declarations: [TribesComponent]
})
export class TribesModule { }
