import {Component, OnInit, ViewChild} from '@angular/core';
import {ArticleService} from '../../shared/services/article.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../shared/services/auth.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {TribesService} from '../../shared/services/tribes.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import * as moment from "moment";

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css',  '../../../assets/css/styles.css']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticlesComponent implements OnInit {
  public mediaPath = environment.mediaPath;
  @ViewChild('submitModal') binaryModal: ModalDirective;
  public display: boolean = false;
  public displayChild: boolean = false;
  public articleList: any = [];
  public articleComments: any = [];
  public commentText: string = '';
  public commentTextLength: number = this.commentText.length;
  public childCommentText: string;
  public articleId: number;
  public user: any = [];
  public tribeId: number;
  public isParentId: number;
  public tribeName: string;
  public isLike: boolean;
  public tribesList: any = [];
  public isCommentShow: boolean;
  public isCommentHide: boolean;
  public isViewReply: boolean = true;
  public pCommentId: number;
  public selectedTribe: any;


  constructor(private articleService: ArticleService, private toast: ToastrService, public authService: AuthService,
              private tribesService: TribesService, private router: Router) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit(): void {
    this.fetchTribes();
    // this.fetchArticle();
  }

  fetchTribes(): void{
    this.tribesService.getDiscussionTribes().subscribe(results => {
      this.tribesList = results.data;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  viewArticles(){
    // this.tribeId = id;
    if (this.selectedTribe !== 'undefined'){
      this.fetchArticle(this.selectedTribe);
    }

  }

  fetchArticle(tribe_id: number): void{
    this.articleService.getArticlesByTribeId(tribe_id).subscribe(results => {
      this.articleList = results.articles;
      // const el = document.getElementById(element);
      // el.scrollIntoView({ behavior: 'smooth' });
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }

  toggleComments(item: any): void {
    this.articleComments = [];
    this.articleId = item.id;
    if (this.articleId){
      this.fetchArticleComments(this.articleId);
      this.display = true;
    }

    console.log(this.articleComments, 'comments');
    
  }

  fetchArticleComments(article_id): void{
    this.articleService.getArticlesComments(article_id).subscribe(results => {
      this.articleComments = results.comments;
      if(results.code === '403'){
        this.toast.error(results.message);
      }
    }, error => {
      this.toast.error(error.error.message);
    });
  }

  postChildComment(comment): void{
    this.isParentId = comment.id;
    // this.isChildId = comment
    this.displayChild = !this.displayChild;
  }

  deleteComment(id) {
     const formData = new FormData();
    formData.append('comment_id', id);
    this.articleService.deleteComment(formData).subscribe(results => {
      if(results.code === '200'){
        this.toast.success(results.message);
      }
      this.fetchArticleComments(this.articleId);
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }

  postLike(comment_id: number){
    this.articleService.postLike(comment_id).subscribe(results => {
      this.fetchArticleComments(this.articleId);
      const {data} = results;
      this.isLike  = data[0].is_current_user_liked;

    }, error => {
      this.toast.error(error.error.error_description);
    });
  }

  submitComment(val){
    let commonText = val == 'child' ? this.childCommentText : this.commentText

    if (commonText === '' || commonText === undefined){
      return this.toast.warning('Please add your comment','');
    }
    if (!this.user.id){
      return this.toast.warning('Please login to add comment','');
    }
    if(val === 'child'){
      var parent_id = this.isParentId;
    } else {
      var parent_id = 0;
    }
    const newCommit = this.addAnchorsToText(commonText);
    const body = {article_id: this.articleId, comment: newCommit, parent_comment_id: parent_id};
    this.articleService.postArticleComment(body).subscribe(results => {
      if(results.code === '200'){
        if(val == 'child') {
          this.childCommentText = ''
          this.displayChild = false;
        } else {
          this.commentText = '';
        this.displayChild = false;
        }
        this.fetchArticleComments(this.articleId);
      }
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }

  submitJoinTribe(): void{
    const params = {tribe_id: this.tribeId, user_id: this.user.id};
    this.tribesService.joinTribe(params).subscribe(results => {
      this.fetchArticle(this.tribeId);
      this.toast.success(results.message);
      this.binaryModal.hide();
      // this.router.navigate(['/user/dashboard']);
      // this.tribesList = results.success;
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }

  checkUserForJoin(item): void{
    if (this.authService.isAuthenticated()){
      this.tribeId = item.tribe_id;
      this.tribeName = item.tribe_name;
      this.binaryModal.show();
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  option1Chosen(): void{
    this.binaryModal.hide();
  }

  joinTribe(): void{
    this.submitJoinTribe();
  }

  // getMinimalisticRelativeTime(dateTime) {
  //   if (!dateTime) {
  //     return null;
  //   }

  //   const today = moment();

  //   const time = moment.utc(dateTime);

  //   const diff = today.diff(time);

  //   const duration = moment.duration(diff);
  //   if (duration.years() > 0) {
  //     return duration.years() + 'y';
  //   } else if (duration.weeks() > 0) {
  //     return duration.weeks() + 'w';
  //   } else if (duration.days() > 0) {
  //     return duration.days() + 'd';
  //   } else if (duration.hours() > 0) {
  //     return duration.hours() + 'h';
  //   } else if (duration.minutes() > 0) {
  //     return duration.minutes() + 'm';
  //   } else if (duration.minutes() < 1) {
  //     return '1s';
  //   }
  // }

  getMinimalisticRelativeTime(inputDate: string): string {
    const inputTime = new Date(inputDate.replace(' ', 'T') + 'Z').getTime() / 1000; // Convert input date string to seconds in UTC
    const currentTime = Date.now() / 1000; // Current time in seconds
    const timeDifference = Math.abs(currentTime - inputTime) - 139;
    console.log(timeDifference, 's');
    

    if (timeDifference < 60) {
      return `just now`;
    } else if (timeDifference < 3600) {
      const minutes = Math.floor(timeDifference / 60);
      return `${minutes}m`;
    } else if (timeDifference < 86400) {
      const hours = Math.floor(timeDifference / 3600);
      return `${hours}h`;
    } else if (timeDifference < 604800) {
      const days = Math.floor(timeDifference / 86400);
      return `${days}d`;
    } else if (timeDifference < 2592000) {
      const weeks = Math.floor(timeDifference / 604800);
      return `${weeks}w`;
    } else {
      const months = Math.floor(timeDifference / 2592000); // Approximating a month as 30 days
      return `${months}m`;
    }
  }

  


  viewComments(id: number){
    this.pCommentId = id;
    this.isCommentShow = !this.isCommentShow;
    // this.isCommentHide = true;
    // this.isViewReply = false;
  }

  hideComment(id: number){
    this.pCommentId = id;
    this.isCommentShow = !this.isCommentShow;
    this.isCommentHide = false;
    this.isViewReply = true;
  }


  onKeyup() {
    this.addAnchorsToText(this.commentText);
  }

  insertIntoString(str: string, pos: number, fragment: string) {
    return str.slice(0, pos) + fragment + str.slice(pos + 1, str.length);
  }

  addAnchorsToText(text) {
    // https://www.regextester.com/94502
    const regexp = /((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+%,;=.]+)/igm;
    const map = {};
    return text.replace(regexp, (_, match, __, pos) => {
        // This map helps us to keep a count of the duplicate matches.
        if (map[match] !== undefined) map[match]++;
        else map[match] = 0;

        // These next few lines handle duplicates. We want to handle cases like this: 
        // <a href="myurl.com">foo</a> bar baz myurl.com.
        let testString = text.replace(new RegExp(escapeRegExp(match), 'ig'), '$');
        let newPos = pos - map[match] * (match.length - 1);
        testString = this.insertIntoString(testString, newPos, match);

        // Detect if the url was found here -> <a href="#here#">
        const hrefRegex = new RegExp(`<a[^>]*href="${escapeRegExp(match)}"[^>]*>[^<]*<\/a>`, 'ig');
        const isHref = hrefRegex.test(testString);

        // Detect if the url was found here -> <a href="...">#here#</a>
        const anchoredRegex = new RegExp(`<a[^>]*>[^<]*${escapeRegExp(match)}[^<]*<\/a>`, 'ig');
        const isAnchored = anchoredRegex.test(testString);

        // console.log({match, isAnchored, isHref});
        // If the url is found in href or anchor, then do nothing with it, it needs no further treatment.
        if (isAnchored || isHref) return match;
        const hasProtocol = /^http[s]?:\/\/.*$/igm.test(match);
        return `<a href="${hasProtocol ? '' : 'http://'}${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
    });
    function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
}

}
