<div class="right_section">
  <div class="questions">
    <div class="row mb-2">
      <div class="col-lg-6">
        <h3>Manage Tribes</h3>
      </div>
    </div>
    <table class="table table-bordered table-hover custab q-table">
      <thead>

      <tr>
        <th>ID</th>
        <th>Title</th>
        <th>Description</th>
        <th class="text-center">Joined Users</th>
        <th class="text-center">Add User</th>
        <!--<th>Points</th>-->
      </tr>
      </thead>
      <ng-container *ngIf="manageTribeList && manageTribeList.length > 0">
        <tr *ngFor="let item of manageTribeList; let i = index">
          <td>{{i+1}}</td>
          <td>{{item.title}}</td>
          <td>{{item.description}}</td>
          <td class="text-center"><button class="btn btn-success" (click)="seeJoinedTribes(item.id)">See Joined Users</button> </td>
          <td class="text-center"><button class="btn btn-success" (click)="addTribeUsers(item.id)">Add User</button> </td>
        </tr>
      </ng-container>
      <div class="item-list no-items-found" *ngIf="manageTribeList.length === 0">
        <span>No Item Found</span>
      </div>

    </table>
  </div>

</div><!--/col-3-->
