
  <div class="right_section">
    <div class="my-members">
      <div>
        <h3>My Members</h3>
      </div>
      <table class="table all-table table-bordered table-hover custab q-table">
        <thead>

        <tr>
          <th>ID</th>
          <th>Image</th>
          <th>Full Name</th>
          <th>Username</th>
          <th>Email</th>
          <th>Status</th>
        </tr>
        </thead>
        <tr>
          <td>1</td>
          <td><img src="assets/images/man.jpg" height="50" width="50"></td>
          <td>Mr Wick</td>
          <td>wich@2323</td>
          <td>wickjone1212@gmail.com</td>
          <td class="text-success">Online</td>
        </tr>
        <tr>
          <td>2</td>
          <td><img src="assets/images/robot.png" height="50" width="50"></td>
          <td>Mr Wick</td>
          <td>wich@2323</td>
          <td>wickjone1212@gmail.com</td>
          <td class="text-success">Online</td>
        </tr>
        <tr>
          <td>3</td>
          <td><img src="assets/images/man.jpg" height="50" width="50"></td>
          <td>Mr Wick</td>
          <td>wich@2323</td>
          <td>wickjone1212@gmail.com</td>
          <td class="text-danger">Offline</td>
        </tr>
        <tr>
          <td>4</td>
          <td><img src="assets/images/robot.png" height="50" width="50"></td>
          <td>Mr Wick</td>
          <td>wich@2323</td>
          <td>wickjone1212@gmail.com</td>
          <td class="text-danger">Offline</td>
        </tr>
        <tr>
          <td>5</td>
          <td><img src="assets/images/robot.png" height="50" width="50"></td>
          <td>Mr Wick</td>
          <td>wich@2323</td>
          <td>wickjone1212@gmail.com</td>
          <td class="text-success">Online</td>
        </tr>

      </table>
    </div>
  </div>


