<footer>
  <div class="container">
    <div class="footer_links">
      <div class="footer_logo">
        <img src="assets/images/logo.png" alt="">
        <p>Copyright © SOCRAI {{currentYear}}. All rights reserved</p>
      </div>
      <div class="pages mt-2">
        <h4>QUICK LINKS</h4>
        <ul class="list-unstyled">
          <li ><a class="" routerLink="/privacy-policy">Privacy Policy</a></li>
      <li><a class="" routerLink="/terms-condition">Terms & Conditions</a></li>
        </ul>
      </div>
      <div class="links">

        <ul>
          <li><a target="_blank" href="https://www.linkedin.com/company/socrai/"><img src="assets/images/linkin.png" alt="" class="img-fluid"></a></li>
          <li><a target="_blank" href="https://business.facebook.com/SOCRAI-100775834738805/?business_id=317944809688572"><img src="assets/images/fb.png" alt="" class="img-fluid"></a></li>
          <li><a target="_blank" href="https://twitter.com/Socrai2?s=20"><img src="assets/images/tw.png" alt="" class="img-fluid"></a></li>
          <li><a  target="_blank" href="https://www.youtube.com/channel/UCkRoGUZH2HSgeRwGSvU5VkA"><img src="assets/images/Youtube_icon_socrai.png" alt="" class="img-fluid"></a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>

<!--<div class="container" *ngIf="authService.isAuthenticated()">-->
  <!--<div class="row">-->
    <!--<div id="Smallchat">-->
      <!--<div class="Layout Layout-open Layout-expand Layout-right" [ngClass]="{'Layout-close': chatSection === true}">-->
        <!--<div class="Messenger_messenger">-->
          <!--<div class="Messenger_header">-->
            <!--<div class="Messenger_prompt">-->
              <!--<img src="assets/images/robo_icon.png" alt="">-->
              <!--<div class="robo_chat">-->
                <!--<span>{{currentTribe.title}}</span>-->
                <!--<p>Level3</p>-->
              <!--</div>-->
            <!--</div> <span class="chat_close_icon" (click)="toggleChat()"><img style="width: 30px" src="assets/images/close.png"></span>-->
          <!--</div>-->
          <!--<div class="Messenger_content">-->
            <!--<div class="Messages">-->
              <!--<div class="Messages_list">-->
                <!--<div class="messages">-->
                  <!--<img src="assets/images/robo_icon.png" alt="">-->
                  <!--<div class="list_msg">-->
                    <!--<p>Hello! Finally found the time to write to you) I need your help.</p>-->
                    <!--<p>Can i send you files</p>-->
                  <!--</div>-->
                <!--</div>-->
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      <!--&lt;!&ndash;===============CHAT ON BUTTON STRART===============&ndash;&gt;-->
      <!--<div class="chat_on" (click)="toggleChat()"> <span class="chat_on_icon"><img src="assets/images/down_arrow.png" alt=""></span> </div>-->
      <!--&lt;!&ndash;===============CHAT ON BUTTON END===============&ndash;&gt;-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->
<!-- End chat -->
