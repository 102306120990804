import {Component, OnInit, ViewChild} from '@angular/core';
import {ArticleService} from '../../shared/services/article.service';
import {Router} from '@angular/router';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.css', '../../../assets/css/styles.css']
})
export class ArticleListComponent implements OnInit {
  public articleList: any = [];
  public user: any = [];
  public userRole: any = [];
  public delArticleId: number;
  @ViewChild('submitModal') binaryModal: ModalDirective;

  constructor(private articleService: ArticleService, private router: Router, private toast: ToastrService) {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.userRole = JSON.parse(localStorage.getItem('userRole'));
    if (this.userRole.role !== 2 && this.userRole.is_leader !== 1){
      this.router.navigate(['/user/dashboard']);
    }
  }

  ngOnInit(): void {
    this.fetchArticle();
  }

  fetchArticle(): void{
    this.articleService.getArticles().subscribe(results => {
      // const data['articles'] = results.data;
      this.articleList = results.articles;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  setArticleId(item): void{
    const id = item.id;
    if (id){
      this.router.navigate(['user/update-article/' + id]);
    }
  }

  showDeleteArticle(item): void{
    if (item) {
      this.delArticleId = item.id;
      this.binaryModal.show();
    }
  }

  option1Chosen(): void{
    this.binaryModal.hide();
  }

  setDeleteArticle(): void{
    this.deleteArticle();
  }
  deleteArticle(): void{
    this.articleService.deleteArticles(this.delArticleId).subscribe(results => {
      this.fetchArticle();
      this.toast.success(results.message);
      this.binaryModal.hide();
      // this.router.navigate(['/user/dashboard']);
      // this.tribesList = results.success;
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }

  dateFormat(date: any) {
    var d = new Date(date);
    return (d.getMonth() + 1) + '-' +  d.getDate() + '-' + d.getFullYear() ;
  }
}
