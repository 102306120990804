<nav class="navbar navbar-expand-lg navbar-dark new-custom-navbar fixed-top">
  <!-- Text Logo - Use this if you don't have a graphic logo -->
  <!-- <a class="navbar-brand logo-text page-scroll" href="index.html">Evolo</a> -->

  <!-- Image Logo -->
  <a class="navbar-brand logo-image" routerLink="/"><img src="assets/images/logo.png" alt="alternative"></a>

  <!-- Mobile Menu Toggle Button -->
  <button class="navbar-toggler" (click)="toggleState()" type="button" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-awesome fa fa-bars"></span>
    <!--<span class="navbar-toggler-awesome fas fa-times"></span>-->
  </button>
  <!-- end of mobile menu toggle button -->

  <div class="navbar-collapse menu-show" [ngClass]="{'menu-show': isIn === true , 'menu-hide': isIn === false }">
    <ul class="navbar-nav ml-auto">
      <!-- Dropdown Menu -->
      <!--<li class="nav-item dropdown">-->
        <!--<a class="nav-link dropdown-toggle page-scroll" href="#about" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">Question socrai</a>-->
        <!--<div class="dropdown-menu" aria-labelledby="navbarDropdown">-->
          <!--<a class="dropdown-item" href="#"><span class="item-text">DropDown 2</span></a>-->
          <!--<div class="dropdown-items-divide-hr"></div>-->
          <!--<a class="dropdown-item" href="#"><span class="item-text">DropDown 1</span></a>-->
        <!--</div>-->
      <!--</li>-->
      <!-- end of dropdown menu -->
      <!--<li class="nav-item">-->
        <!--<a class="nav-link page-scroll" routerLink="/">Home</a>-->
      <!--</li>-->
      <!--<li class="nav-item">-->
        <!--<a class="nav-link page-scroll" href="#">Covid-19</a>-->
      <!--</li>-->
      <!--<li class="nav-item">-->
        <!--<a class="nav-link page-scroll" href="#">Contact Us</a>-->
      <!--</li>-->
      <!--<li class="nav-item">-->
        <!--<a class="nav-link page-scroll" routerLink="/join-tribes">Join Tribes</a>-->
      <!--</li>-->
      <!--<li class="nav-item" *ngIf="authService.isAuthenticated()">-->
        <!--<a class="nav-link page-scroll" routerLink="/discussion">Discussions</a>-->
      <!--</li>-->
      <li class="nav-item" *ngIf="!authService.isAuthenticated()">
        <a [routerLinkActive]="['menu-active']" class="nav-link page-scroll" routerLink="/auth/login">Login</a>
      </li>
      <li class="nav-item" *ngIf="!authService.isAuthenticated()">
        <a [routerLinkActive]="['menu-active']" class="nav-link page-scroll" routerLink="/auth/register">Sign up</a>
      </li>
      <li class="nav-item" *ngIf="authService.isAuthenticated()">
        <a [routerLinkActive]="['menu-active']" class="nav-link page-scroll" routerLink="/user/profile" (click)="setActive('profile')">Profile</a>
      </li>
      <li class="nav-item" *ngIf="authService.isAuthenticated()" (click)="logout()" style="cursor: pointer">
        <a class="nav-link page-scroll">Logout</a>
      </li>
    </ul>
  </div>
</nav>
