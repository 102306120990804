import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {JoinTribesComponent} from './join-tribes.component';


const routes: Routes = [
  { path: 'tribes', component: JoinTribesComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JoinTribesRoutingModule { }
