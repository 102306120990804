import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from "../shared/shared.module";
import {AdminComponent} from "./admin.component";
import {AdminRoutingModule} from "./admin-routing.module";
import { MyPointsComponent } from './my-points/my-points.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ManageTribesComponent } from './manage-tribes/manage-tribes.component';
import { TribeUsersComponent } from './tribe-users/tribe-users.component';
import { AddTribeUserComponent } from './add-tribe-user/add-tribe-user.component';
import { TribesRequestsComponent } from './tribes-requests/tribes-requests.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import {AllMediaComponent} from './all-media/all-media.component';
import { NewpipePipe } from '../shared/pipes/newpipe.pipe';


@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    FormsModule,
    ModalModule,
    ReactiveFormsModule
  ],
  declarations: [AdminComponent, MyPointsComponent, TribesRequestsComponent, AllMediaComponent, NewpipePipe]
})
export class AdminModule { }
