import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {JoinTribesRoutingModule} from './join-tribes-routing.module';
import {FormsModule} from '@angular/forms';
import {JoinTribesComponent} from './join-tribes.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    JoinTribesRoutingModule,
    FormsModule,
    ModalModule.forRoot(),
    NgbNavModule
  ],
  declarations: [JoinTribesComponent]
})
export class JoinTribesModule { }
