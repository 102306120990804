import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TribesService } from 'src/app/shared/services/tribes.service';

@Component({
    selector: 'app-register-page',
    templateUrl: './register-page.component.html',
    styleUrls: ['./register-page.component.scss', '../../../assets/css/login.css']
})

export class RegisterPageComponent implements OnInit {
  @ViewChild('f') registerForm: NgForm;
  public signUpForm: FormGroup;
  public passwordForm: FormGroup;
  public passMatchCheck = true;
  public submitted = false;
  data: any
  isLoader = false;
  isLoading = false;
  btnDisable = true;
  returnUrl: string;
  hide: boolean = true;
  modalRef?: BsModalRef;
  constructor(private  router: Router, private route: ActivatedRoute, private toast: ToastrService,
              private authService: AuthService, private fb: FormBuilder, private modalService: BsModalService, private tribesService: TribesService) {
  }

  get f() { return this.signUpForm.controls;
   }

  ngOnInit() {
    this.loadBasicInfo();
    this.fetchTerms();

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (this.isUserLoggedIn()) {
      this.router.navigate(['/user/dashboard']);
    }
  }

  fetchTerms(): void{
    this.tribesService.getTermsPage().subscribe(results => {
      this.data = results.data;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  isUserLoggedIn() {
    return this.authService.getSessionUser();
  }

  loadBasicInfo(): void{
    this.signUpForm = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(50)])],
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(50)])],
      c_password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]],
    }, {validator : this.checkPasswords});
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.get('password').value;
    const confirmPass = group.get('c_password').value;
    return (pass === confirmPass) ? true : false;
  }

  valueChange(newValue) {
    if (this.signUpForm.controls['c_password'].value !== ''){
      if (!this.checkPasswords(this.signUpForm)){
        this.signUpForm.controls['c_password'].setErrors({'incorrect': true});
        this.passMatchCheck  = false;
      } else{
        this.signUpForm.controls['c_password'].clearValidators();
        this.passMatchCheck  =  true;
      }
    } else{
      this.passMatchCheck  =  true;
    }
  }

  onCheckboxChange(e) {
    console.log('testing')
    if (e.target.checked) {
      this.btnDisable = false;
    } else {
      this.btnDisable = true;
    }
  }

  submit(form): void{
    this.submitted = true;
    this.isLoader = true;
    this.isLoading = true;
    if (this.signUpForm.invalid) {
      this.isLoader= false;
      return;
    }
    this.authService.register(form.value).subscribe(data => {
      if (data.code === '200'){
        // this.verificationEmail(form.value.email);
        window.localStorage.setItem('verificationEmail', data.data.email);
        this.toast.success(data.message, 'Success');

        this.router.navigate(['/auth/email-verification']);
        this.isLoader = false;
        this.isLoading = false;
      }

    }, (error: any) => {
        this.toast.error(error.error.error_description.email);
        this.isLoader = false;
        this.isLoading = false;
      });
  }

  showPassword() {
    this.hide = !this.hide;
  }

  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template,{ class: 'modal-lg' });
  }
}
