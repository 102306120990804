import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './admin.component';
import {DashboardModule} from './dashboard/dashboard.module';
import {ProfileModule} from './profile/profile.module';
import {MembersModule} from './my-members/members.module';
import {QuestionsModule} from './my-questions/questions.module';
import {AuthenticationGuard} from '../shared/guard/auth.guard';
import {PasslevelModule} from './pass-levels/passlevel.module';
import {TribesModule} from './tribes/tribes.module';
import {ArticleListModule} from './article-list/articlelist.module';
import {AddArticleModule} from './add-article/add-article.module';
import {ManageTribesModule} from './manage-tribes/managetribes.module';
import {TribeUsersModule} from './tribe-users/tribeusers.module';
import {AddTribeUsersModule} from './add-tribe-user/addtribeusers.module';
import { TribesRequestsComponent } from './tribes-requests/tribes-requests.component';
import {AllMediaComponent} from './all-media/all-media.component';


const routes: Routes = [
  {  path: '', component: AdminComponent, canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        loadChildren: () => DashboardModule
      },
      {
        path: '',
        loadChildren: () => ProfileModule
      },
      {
        path: '',
        loadChildren: () => MembersModule
      },
      {
        path: '',
        loadChildren: () => QuestionsModule
      },
      {
        path: '',
        loadChildren: () => PasslevelModule
      },
      {
        path: '',
        loadChildren: () => TribesModule
      },

      {
        path: '',
        loadChildren: () => ArticleListModule
      },

      {
        path: '',
        loadChildren: () => AddArticleModule
      },
      {
        path: '',
        loadChildren: () => ManageTribesModule
      },
      {
        path: '',
        loadChildren: () => TribeUsersModule
      },
      {
        path: '',
        loadChildren: () => AddTribeUsersModule
      },
      { path: 'tribes-requests', component: TribesRequestsComponent },
      { path: 'all-media', component: AllMediaComponent }
    ]},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
