import { Component, OnInit } from '@angular/core';
import {TribesService} from '../../shared/services/tribes.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  public data: any;
  constructor(private tribesService: TribesService) { }

  ngOnInit(): void {
    this.fetchPrivacy();
  }

  fetchPrivacy(): void{
    this.tribesService.getPrivacyPage().subscribe(results => {
      this.data = results.data;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

}
