import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AddArticleRoutingModule} from './add-article-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddArticleComponent} from './add-article.component';

@NgModule({
  imports: [
    CommonModule,
    AddArticleRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [AddArticleComponent]
})
export class AddArticleModule { }
