import { Component, OnInit } from '@angular/core';
import {TribesService} from '../../shared/services/tribes.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tribes',
  templateUrl: './tribes.component.html',
  styleUrls: ['./tribes.component.css', '../../../assets/css/styles.css']
})
export class TribesComponent implements OnInit {
  public tribesList: any = [];
  public user: any = [];
  constructor(private tribesService: TribesService, private toast: ToastrService, private router: Router) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit(): void {
    this.fetchJoinedTribes();
  }


  fetchJoinedTribes(): void{
    this.tribesService.getJoinedTribes().subscribe(results => {
      this.tribesList = results.data;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  getTribeId(id: number){
      this.router.navigate(['tribes-detail/' + id]);
  }

  // joinTribe(id: number){
  //   const params = {tribe_id: id, user_id: this.user.id}
  //   this.tribesService.joinTribe(params).subscribe(results => {
  //     this.toast.success(results.success);
  //     // this.tribesList = results.success;
  //   }, error => {
  //     // this.toast.error(error1.error.error_description);
  //   });
  // }
}
