import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css', '../../../assets/css/styles.css']
})
export class ProfileComponent implements OnInit {
  public mediaPath = environment.mediaPath;
  public user: any = [];
  public newPassword: string = '';
  public errorMessage: string = '';
  fileToUpload: File = null;
  public imageThumbLoad;
  public imageSrc: any;
  public removeType: any;
  public imgType: any;
  public passwordCheck: boolean;
  public confirmPassword: any;
  hide: boolean = true;
  constructor(private authService: AuthService, private toast: ToastrService) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user.image !== ''){
      this.imageSrc = this.mediaPath + this.user.image;
    }
    setTimeout(() => {
      this.imageThumbLoad = <HTMLCanvasElement> document.getElementById('imageThumbLoad');
      this.imageThumbLoad.addEventListener('change', this.handleFileInput.bind(this), false);
    }, 0);
  }

  updateProfile(): void {
    if (!this.user.name || !this.user.email){
      this.toast.error('Name and email Required');
      return;
    }
    const body = {name: this.user.name, email: this.user.email, password: this.newPassword, image: this.formData()};
    const userId = this.user.id;
    this.authService.updateUserProfile(this.formData()).subscribe(results => {
      if (results.code = '200'){
        this.newPassword = '';
        this.confirmPassword = '';
        this.toast.success('Profile successfully update', '');
        this.getUser();
      }
    }, error => {
      this.toast.error(error.error.error_description.error);
    });

  }

  getUser(): void{
    const userId = this.user.id;
    this.authService.getUserProfile().subscribe(results => {
      const user = results.message;
      if (user.image !== ''){
        this.imageSrc = this.mediaPath + user.image;
      }
      window.localStorage.setItem('user', JSON.stringify(user));
      window.location.reload();
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  handleFileInput(event, files: FileList) {
    // Images only
    // const str = files('image/');
    const str = files[0].type.split('image/');
    const type = str[1];
    if (type !== 'png' && type !== 'jpg' && type !== 'jpeg' && type !== 'PNG') {
      this.errorMessage = 'Invalid File';
      return;
    }
    this.errorMessage = '';
    this.fileToUpload = files.item(0);
  }

  handleInputChange(e): void {
    const file: File = e.target?.files[0];
    const str = file?.type.split('image/');
    const type = str[1];
    const dot = '.';
    this.removeType = type;
    this.imgType = dot + type;
    // this.formBasic.controls['imageType'].setValue(this.imgType);
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.errorMessage = 'Invalid image';
      return;
    } else {
      this.errorMessage = '';
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e): void {
    const reader = e.target;
    const str = reader.result.split('data:image/' + this.removeType + ';base64,');
    this.imageSrc = reader.result;
  }

  formData() {
    const formDataUpload: FormData = new FormData();
    formDataUpload.append('name', this.user.name);
    formDataUpload.append('email', this.user.email);
    formDataUpload.append('password', this.newPassword);
    formDataUpload.append('con_password', this.confirmPassword )
    formDataUpload.append('image', this.fileToUpload);
    return formDataUpload;
  }

  checkPassword(event) {
    const password = this.newPassword;
    const confirmPassword = event.target.value;

    if (password !== undefined) {
      if (confirmPassword !== password) {
        this.passwordCheck = true;
      } else {
        this.passwordCheck = false;
      }
    }
  }

  showPassword() {
    this.hide = !this.hide;
  }

  removeImage(): void {
    const userId = this.user.id;
    const body = {user_id: userId};
    this.authService.deleteProfileImage(body).subscribe(results => {
        this.getUser();
    }, error => {
      this.toast.error(error.error.error_description.error);
    });
  }

}
