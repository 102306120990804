import { Component, HostListener } from '@angular/core';
import {AuthService} from '../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {
    currentLang = 'en';
    isIn = false;   // store state
    toggleClass = 'ft-maximize';
    activeTab: string;
    constructor(public authService: AuthService, private toast: ToastrService, private router: Router) {
        // const browserLang: string = translate.getBrowserLang();
        // translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
        this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
          this.checkRoute();
        });
    }

    private checkRoute(): void {
      const currentUrl = this.router.url;
      if (currentUrl.includes('/user/tribes') || currentUrl.includes('/user/question') || currentUrl.includes('/user/dashboard')) {
        this.isIn = false
      }
    }

    ChangeLanguage(language: string) {
        // this.translate.use(language);
    }

    // ToggleClass() {
    //     if (this.toggleClass === 'ft-maximize') {
    //         this.toggleClass = 'ft-minimize';
    //     } else
    //         this.toggleClass = 'ft-maximize'
    // }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
      this.isIn = false;
    }
    
  toggleState() { // click handler
    this.isIn = !this.isIn
  }

  logout(){
    this.onSubmit();
  }

  onSubmit() {
    // this.authService.logout();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    const body = {user_id: user.id, access_token: token};
    this.authService.logoutUser(body).subscribe((data) => {
      this.authService.logout();
    }, error => {
      this.toast.error(error.error.error_description);
    });

  }

  setActive(val: string){

      this.activeTab = val;
  }
}
