import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ArticleListRoutingModule} from './articlelist-routing.module';
import {ArticleListComponent} from './article-list.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BaDate} from '../../shared/pipes/baDate.pipe';

@NgModule({
  imports: [
    CommonModule,
    ArticleListRoutingModule, ModalModule.forRoot()
  ],
  declarations: [ArticleListComponent, BaDate]
})
export class ArticleListModule { }
