import {Component, Injectable, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from '../../shared/services/auth.service';
import {NoWhiteSpaceValidator} from '../../shared/custom-validator/noWhitespace.validator';
import {ToastrService} from 'ngx-toastr';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',
    '../../../assets/css/login.css'
  ]
})

export class LoginComponent implements OnInit {
  @ViewChild('submitModal') binaryModal: ModalDirective;
  public loading: any = false;
  public user: any = [];
  public loginForm: FormGroup;
  public submitted = false;
  returnUrl: string;
  // Password Show
  hide: boolean = true;

  constructor(private router: Router, private toast: ToastrService,
              private route: ActivatedRoute, private authService: AuthService,
              public auth: AuthService, private fb: FormBuilder) {

  }
  get f() { return this.loginForm.controls; }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (this.isUserLoggedIn()) {
      this.router.navigate(['/user/dashboard']);
    }
    this.loadBasicInfo();
    }

  isUserLoggedIn() {
    return this.authService.getSessionUser();
  }


  loadBasicInfo(): void{
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')])],
      password: ['', Validators.compose([Validators.required, NoWhiteSpaceValidator.cannotContainSpace,
        Validators.minLength(7), Validators.maxLength(20)])],
    });
  }

  // On submit button click
  onSubmit(param: any) {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    console.log(param);
    
    this.authService.login(param.value).subscribe((data: any) => {

      console.log(data, 'data');
      
      if(data.code === '401') {
        this.router.navigate(['/auth/email-verification']);
      }

      this.user = data;
      if (data.user.email_varified === 1){
        if (this.user.user.change_password_popup_cehck === 0){
          window.localStorage.setItem('userRole', JSON.stringify( data.user));
          window.localStorage.setItem('user', JSON.stringify( data.user));
          window.localStorage.setItem('token', JSON.stringify(data.tokken));
          this.loading = false;
          this.router.navigate(['/user/dashboard']);
        } else if (this.user.user.change_password_popup_cehck === 1){
          this.binaryModal.show();
          window.localStorage.setItem('userRole', JSON.stringify( data.user));
          window.localStorage.setItem('user', JSON.stringify( data.user));
          window.localStorage.setItem('token', JSON.stringify(data.tokken));
          this.loading = false;
        }
      } else {
        window.localStorage.setItem('verificationEmail', data.user.email);
        this.router.navigate(['/auth/email-verification']);
        this.toast.success('Please verify your code', 'Success');
      }


    }, error => {
      this.toast.error(error.error.error_description.error);
    });

  }

  confirm(){
    this.binaryModal.hide();
    this.router.navigate(['/user/profile']);
  }

  // On Forgot password link click
  onForgotPassword(): void {
    this.router.navigate(['/forgot-password']);
  }

  // On registration link click
  onRegister(): void {
    this.router.navigate(['/register']);
  }

  showPassword() {
    this.hide = !this.hide;
  }
}
