<div class="right_section">
    <div class="questions">
      <div class="row mb-2">
        <div class="col-lg-6">
        <h3>My Tribes</h3>
        </div>
      </div>
      <table class="table table-bordered table-hover custab q-table">
        <thead>

        <tr>
          <th>ID</th>
          <th>Email</th>
          <th>User Name</th>
          <th>Tribe Name</th>
          <th>Action</th>
          <!--<th>Points</th>-->
        </tr>
        </thead>
        <ng-container *ngIf="requestJoinTribesList && requestJoinTribesList.length > 0">
            <tr *ngFor="let item of requestJoinTribesList; let i = index">
              <td>{{i+1}}</td>
              <td>{{item.user_email}}</td>
              <td>{{item.user_name}}</td>
              <td>{{item.tribe_name}}</td>
              <td class="text-center">
                <button (click)="acceptJoinRequest(item)" class="btn btn-success">Accept Request</button>
                <button class="btn btn-success" (click)="showModal(item.user_id, item.tribe_id)" style="margin-left: 5px">Decline</button>
              </td>
            </tr>
          </ng-container>
          <div class="item-list no-items-found" *ngIf="requestJoinTribesList.length === 0">
            <span>No Item Found</span>
          </div>


      </table>
    </div>

  </div>


<div bsModal #submitModal="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
[config]="{'backdrop':'static', 'keyboard': false}">

<div class="modal-dialog" role="document">
<div class="modal-content">
 <div class="modal-body text-center">
   <span class="text-center">
     <h2 style="margin-top: 77px;font-size: 22px; color: #3D3D3D">Are you sure want to Decline this request?</h2>
   </span>
   <div class="justify-content-center" style="margin-top: 50px;margin-bottom: 20px">
     <button class="btn btn-danger" type="button" (click)="option1Chosen()" data-dismiss="modal" style="width: 30%;margin-right: 20px">Cancel</button>
     <button class="btn btn-success" type="button" (click)="declineJoinRequest()" data-dismiss="modal" style="width: 30%">Yes</button>
   </div>
 </div>
</div>
</div>
</div>

  <!-- <div bsModal #deleteModal="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
       [config]="{'backdrop':'static', 'keyboard': false}">

    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <span class="text-center">
            <h2 style="margin-top: 77px;font-size: 22px; color: #3D3D3D">Are you sure want to delete this article?</h2>
          </span>
          <div class="justify-content-center" style="margin-top: 50px;margin-bottom: 20px">
            <button class="btn btn-danger" type="button" (click)="option1Chosen()" data-dismiss="modal" style="width: 30%;margin-right: 20px">Cancel</button>
            <button class="btn btn-success" type="button" (click)="setDeleteArticle()" data-dismiss="modal" style="width: 30%">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div> -->


  <!-- <div bsModal #submitModal="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
     [config]="{'backdrop':'static', 'keyboard': false}">

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <span class="text-center">
          <h2 *ngIf="joinStatus === 'join'" style="margin-top: 77px;font-size: 22px; color: #3D3D3D">Are you sure want to join this tribe?</h2>
          <h2 *ngIf="joinStatus === 'unJoin'" style="margin-top: 77px;font-size: 22px; color: #3D3D3D">Are you sure want to UnJoin this tribe?</h2>
        </span>
        <div class="justify-content-center" style="margin-top: 50px;margin-bottom: 20px">
          <button class="btn btn-danger" type="button" (click)="option1Chosen()" data-dismiss="modal" style="width: 30%;margin-right: 20px">Cancel</button>
          <button *ngIf="joinStatus === 'join'" class="btn btn-success" type="button" (click)="joinTribe()" data-dismiss="modal" style="width: 30%">Yes</button>
          <button *ngIf="joinStatus === 'unJoin'" class="btn btn-success" type="button" (click)="unJoinTribe()" data-dismiss="modal" style="width: 30%">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div> -->
