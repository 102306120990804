import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { AppComponent } from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpService, HttpServiceCreator} from './shared/services/http.service';
import {AuthService} from './shared/services/auth.service';
import {HashLocationStrategy, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {AuthenticationGuard} from './shared/guard/auth.guard';
import {TribesService} from './shared/services/tribes.service';
import {SharedModule} from './shared/shared.module';
import {DashboardService} from './shared/services/dashboard.service';
import {ArticleService} from './shared/services/article.service';
import {NavigationService} from './shared/services/navigation.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 12000,
      preventDuplicates: true
    }),
    NgbModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, AuthService, TribesService, AuthenticationGuard, NavigationService,
    DashboardService, FormBuilder, ArticleService, {
    provide: HttpService,
    useFactory: HttpServiceCreator,
    deps: [HttpClient]
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
