import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TribesService} from '../../shared/services/tribes.service';
import {environment} from '../../../environments/environment';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-tribe-users',
  templateUrl: './tribe-users.component.html',
  styleUrls: ['./tribe-users.component.css', '../../../assets/css/styles.css']
})
export class TribeUsersComponent implements OnInit {
  @ViewChild('submitModal') binaryModal: ModalDirective;
  public mediaPath = environment.mediaPath;
  public tribeId: number;
  public tribeUsers: any = [];
  public userId: number;

  constructor(private activeRoute: ActivatedRoute, private tribeService: TribesService, private toast: ToastrService) { }

  ngOnInit(): void {
    this.getQueryParam();
    if (this.tribeId){
      this.fetchTribeUsers(this.tribeId);
    }
  }
  getQueryParam(): void {
    this.activeRoute.params.subscribe(params => {
      this.tribeId = params.id;
    });
  }

  fetchTribeUsers(tribe_id: number): void{
    this.tribeService.getTribeUsers(tribe_id).subscribe(results => {
      const {data} = results;
      this.tribeUsers = data;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  deleteUser(): void{
    const params = {tribe_id: this.tribeId, user_id: this.userId};
    this.tribeService.deleteUserFromTribe(params).subscribe(results => {
      const {code} = results;
      const {message} = results;
      if (code === '200'){
        this.fetchTribeUsers(this.tribeId);
        this.binaryModal.hide();
        this.toast.success(message);
      }
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  option1Chosen(): void{
    this.binaryModal.hide();
  }

  showModal(id: number){
    if (id){
      this.binaryModal.show();
      this.userId = id;
    }
  }
}
