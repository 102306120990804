<body data-spy="scroll" data-target=".fixed-top">
  <div class="container">
    <div class="session">
      <div class="left">
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)" class="log-in" autocomplete="off">
        <div class="main_head">
          <h2>LOGIN</h2>
        </div>
        <div class="floating-label">
          <input class="form-control" placeholder="Email" type="text" name="email" id="email" autocomplete="off"
            formControlName="email"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors, '': f.email.touched && !f.email.errors }">
          <label for="email">Email:</label>
          <div class="icon icon-tree">
            <i class="fa fa-envelope"></i>
          </div>
        </div>
        <div class="floating-label">
          <input class="form-control" placeholder="Password" [type]="hide ? 'password' : 'text'" name="password"
            formControlName="password" id="password" autocomplete="off"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors, '': f.password.touched && !f.password.errors }">
          <i [class]="hide ? 'fa fa-eye-slash' : 'fa fa-eye'" (click)="showPassword()" style="position: absolute;
    right: 10px;
    top: 15px;
    font-size: 25px;"></i>
          <label for="password">Password:</label>
          <div class="icon">
            <div class="icon icon-tree">
              <i class="fa fa-lock"></i>
            </div>
          </div>

        </div>

        <!--<div class="floating-label">-->
        <!--<input class="form-control" placeholder="OTP" type="number" name="Otp_token" formControlName="Otp_token" id="Otp_token" autocomplete="off"-->
        <!--[ngClass]="{ 'is-invalid': submitted && f.Otp_token.errors, '': f.Otp_token.touched && !f.Otp_token.errors }">-->
        <!--<label for="Otp_token">OTP:</label>-->
        <!--<div class="icon">-->
        <!--<div class="icon icon-tree">-->
        <!--<i class="fa fa-lock"></i>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <div class="row floating-label mt-1">
          <div class="col-lg-6">
          </div>
          <div class="col-lg-6 text-right">
            <button type="submit" style="margin-right: -50px;">Log in</button>
          </div>
        </div>

        <!--<div class="main-bootom">-->
        <!--<div><a href="#">Home</a></div>-->
        <!--<div><a href="#">Some title centered</a></div>-->
        <!--<div><span routerLink="/auth/forgot-password">Forgot Password?</span></div>-->
        <!--</div>-->

        <div class="row floating-label mt-2">
          <!--<div class="col-lg-12 col-sm-12">-->
          <div class="col-6">
            <div routerLink="/auth/register" class="discrete" style="cursor: pointer;">Create an account</div>
          </div>
          <div class="col-6 text-right">
            <!--<ul>-->
            <div routerLink="/auth/forgot-password" class="discrete" style="margin-right: -50px;cursor: pointer">Forgot
              Password?</div>
            <!--</ul>-->
          </div>
          <!--</div>-->
        </div>

      </form>
    </div>
  </div>
</body>

<div bsModal #submitModal="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{'backdrop':'static', 'keyboard': false}">

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <span class="text-center">
          <h2 style="margin-top: 77px;font-size: 22px; color: #3D3D3D">Please change your password from profile</h2>
        </span>
        <div class="justify-content-center" style="margin-top: 50px;margin-bottom: 20px">
          <button class="btn btn-success" type="button" (click)="confirm()" data-dismiss="modal"
            style="width: 30%">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>