import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthService} from './auth.service';

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

@Injectable()
export class HttpService {
  options: IRequestOptions;
  private baseUrl = environment.apiUrl;

  public constructor(public http: HttpClient) {
  }

  public get<T>(endPoint: string): Observable<T> {
    return this.http.get<T>(`${ this.baseUrl }${ endPoint }`, this.getOptions());
  }

  public post<T>(endPoint: string, params?: Object, options?: IRequestOptions): Observable<T> {
    if (options) {
      return this.http.post<T>(`${ this.baseUrl }${ endPoint }`, params, options);
    } else {
      return this.http.post<T>(`${ this.baseUrl }${ endPoint }`, params, this.getOptions());
    }
  }

  public put<T>(endPoint: string, params: Object): Observable<T> {
    return this.http.put<T>(`${ this.baseUrl }${ endPoint }`, params, this.getOptions());
  }

  public delete<T>(endPoint: string): Observable<T> {
    return this.http.delete<T>(`${ this.baseUrl }${ endPoint }`, this.getOptions());
  }

  getOptions() {
    const lang = localStorage.getItem('lang');
    const token = localStorage.getItem('token');
    if (token){
      const headers = new HttpHeaders({
        'accept-language': lang || 'en',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      });

      const options = {headers};
      return options;
    }

  }
}

export function HttpServiceCreator(http: HttpClient) {
  return new HttpService(http);
}
