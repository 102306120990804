import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Session} from '../models/StorageSessions';
import {HttpService} from './http.service';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class QuestionsService {
	public promise: Promise<boolean>;

	constructor(private http: HttpService, private router: Router) {

  }

  public updateUserProfile(params): Observable<any> {
    const url = `profile` ;
    return this.http.put<any>(url, params);
  }

  public getQuestions(topic_id: number): Observable<any> {
    const url = `get_points_topic_wise`;
    const body = { topic_id: topic_id };
    return this.http.post<any>(url, body);
  }

  public getPoints(): Observable<any> {
    const url = `get_points`;
    return this.http.get<any>(url);
  }

  public getTopics(): Observable<any> {
    const url = `get_topic_list_for_points`;
    return this.http.get<any>(url);
  }

  public getLevels(): Observable<any> {
    const url = `get_level_crossed`;
    return this.http.get<any>(url);
  }

  public getTribes(): Observable<any> {
    const url = `tribes`;
    return this.http.get<any>(url);
  }
  public joinTribe(param): Observable<any> {
    const url = `join/tribe`;
    return this.http.post<any>(url, param);
  }

}
