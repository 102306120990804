import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TribesService} from '../../shared/services/tribes.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../shared/services/auth.service';
import {ChatService, Message} from '../../shared/chat/chat.service';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/scan';
import {scan} from 'rxjs/internal/operators';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {environment} from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-tribes',
  templateUrl: './tribes-detail.component.html',
  styleUrls: ['./tribes-detail.component.scss',  '../../../assets/css/styles.css']
})
export class TribesDetailComponent implements OnInit, AfterViewInit {
  messages: Observable<Message[]>;
  public mediaUrl = environment.mediaPath;
  @ViewChild('enterMsg') enterMsg: ElementRef;
  @ViewChild('submitModal') binaryModal: ModalDirective;
  @ViewChild('imageModal') imagesModal: ModalDirective;
  @ViewChild('videoContent') videoContent: ModalDirective;
  @ViewChild('audioContent') audioContent: ModalDirective;
  @ViewChild('msgArea') msgArea: ElementRef;
  public tribesList: any = [];
  public topicClues: any = [];
  public user: any = [];
  public tribeId: number;
  public topicId: number;
  public chatSection: boolean;
  public currentTopic: any = [];
  public display = false;
  public mediaSectionVideo = false;
  public mediaSectionImage = false;
  public strMsg: string;
  public isJoined: boolean;
  public tribeDetail: any;
  public joinStatus: string;
  public mediaType: string;
  public botMediaImage: string;
  public botMediaVideo: string;
  public botMediaAudio: string;
  public isExternal: string;
  public mediaImage: string;
  public mediaVideo: string;
  public mediaAudio: string;
  public clue: string;
  public type: string;
  public message: string;
  public upgradedLevel: string;
  public vid: any;
  public botMessage: any;
  public isExerciseUnlock: any;
  public exerciseQuestion: any;
  public isJoinRequest: any;
  public inputDisable: boolean = false;

  constructor(private chat: ChatService, private tribesService: TribesService,
    private sanitizer: DomSanitizer,
              private router: Router, private toast: ToastrService,
              private route: ActivatedRoute,
              public authService: AuthService,
              private elementRef: ElementRef) {
    this.user = JSON.parse(localStorage.getItem('user'));
    // this.role = JSON.parse(localStorage.getItem('role'));
    this.getQueryParam();
  }

  ngOnInit(): void {
    // this.loadMessage();
    if (this.tribeId){
      this.fetchTribesTopics(this.tribeId);
    }
  }

  ngAfterViewInit(){
    this.enterMsg.nativeElement.focus();
  }

  loadMessage(): void{
    this.botMessage = new Message('', 'bot');
    this.chat.update(this.botMessage);
    this.messages = this.chat.conversation.asObservable()
      .pipe(
        scan((acc, val) => {
          setTimeout(() => {
            this.msgArea.nativeElement.scrollTop = this.msgArea.nativeElement.scrollHeight;
          });
          return acc.concat(val);
        } )
      );
  }

  getQueryParam(): void {
    this.route.params.subscribe(params => {
      this.tribeId = params.id;
    });
  }

  setTribe(item): void{
    if (item){
      this.currentTopic = item;
      if (this.isJoined === true){
        // this.display = false;
        this.display = true;
        // this.mediaSection = true;
        // this.toggleChat();
        this.fetchTopicClues(item.id);
        this.loadMessage();
      } else {
        this.toast.warning('Your topic will be shortly available once the tribe leader validate your request');
      }
      this.topicId = item.id;
      localStorage.setItem('isTribeUser', 'true');
      this.tribesService.emitTribe(item);
    }
  }


  fetchTribesTopics(tribe_id: number): void{
    this.tribesService.getTribesTopics(tribe_id).subscribe(results => {
      this.tribesList = results.data;
      this.isJoined =  results.tribe_details.is_joined;
      this.tribeDetail = results.tribe_details[0];
      this.isJoinRequest = results.is_join_request_sent;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  fetchTopicClues(topic_id: number): void{
    this.topicClues = [];
    this.tribesService.getTopicClues(topic_id).subscribe(results => {
      const {message} = results;
      this.topicClues = message;
      this.message = message;
      if (this.topicClues !== null){
        // this.botMessageLoad(this.message);
        this.botMessage = new Message('' + ' ' + this.topicClues, 'bot');
        this.chat.update(this.botMessage);
        this.enterMsg.nativeElement.focus();
      }

    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  botMessageLoad(val: string): void{
    this.botMessage = new Message('' + ' ' + val, 'bot');
    this.chat.update(this.botMessage);
  }

  extractContent(html) {
    return new DOMParser().parseFromString(html, "text/html").documentElement.textContent;
}

  toHtml(str) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  }

  submitQuestion(): void{
    if (this.strMsg === '' || this.strMsg === undefined){
      return;
    } else {
      const userMessage = new Message(this.strMsg, 'user');
      this.inputDisable = true;
      this.chat.update(userMessage);
    }

    if (this.isExerciseUnlock === 'true'){
        var isUnlock = true;
        var exeQuestion = this.exerciseQuestion;
    } else {
         isUnlock = false;
         exeQuestion = false;
    }

    const body = {question: this.strMsg, topic_id : this.topicId, is_exercise: isUnlock, exercise_question: exeQuestion};
    this.strMsg = '';
    this.chat.converse(body).subscribe(results => {
      this.clue = results.clue;
      // this.message = results.message;
      this.inputDisable = false;
      this.upgradedLevel = results.upgrade_level;
      this.isExternal = results.external;
      this.mediaType = results.type;
      this.isExerciseUnlock = results.exercise;
      this.exerciseQuestion = results.exercise_question;
      debugger;
      const botAnswer = results.data;
      if (this.isExternal === 'true' && this.mediaType === 'image'){
        this.mediaImage = results.media_path;
      } else if (this.isExternal === 'false' && this.mediaType === 'image'){
        this.mediaImage = this.mediaUrl + results.media_path;
      }

      if (this.isExternal === 'true' && this.mediaType === 'video'){
        this.botMediaVideo = results.media_path;
      } else if (this.isExternal === 'false' && this.mediaType === 'video'){
        this.botMediaVideo = this.mediaUrl + results.media_path;
      }

      if (this.isExternal === 'true' && this.mediaType === 'audio'){
        this.botMediaAudio = results.media_path;
      } else if (this.isExternal === 'false' && this.mediaType === 'audio'){
        this.botMediaAudio = this.mediaUrl + results.media_path;
      }
      const botMessage = new Message(botAnswer, 'bot' , this.mediaImage,
        this.botMediaVideo, this.botMediaAudio, this.clue, this.message, this.upgradedLevel,
        this.mediaType, results.exercise, results.exercise_notification, results.exercise_question,
        results.actual_answer, results.confidence_level);
        console.log(botMessage, 'result testing')
      this.chat.update(botMessage);
      this.enterMsg.nativeElement.focus();
    }, error => {
      // this.toast.error(error1.error.error_descriptFion);
    });
  }

  toggleChat(): void {
    this.display = !this.display;
  }

  toggleMediaVideo(): void {
    this.mediaSectionVideo = !this.mediaSectionVideo;
  }

  toggleMediaImage(): void {
    this.mediaSectionImage = !this.mediaSectionImage;
  }

  checkUserForJoin(val): void{
    if (this.authService.isAuthenticated()){
      this.joinStatus = val;
      this.binaryModal.show();
    } else {
      this.router.navigate(['/auth/login']);
    }
  }


  submitJoinTribe(): void{
    const params = {tribe_id: this.tribeId, user_id: this.user.id};
    this.tribesService.joinTribe(params).subscribe(results => {
      this.fetchTribesTopics(this.tribeId);
      this.toast.success(results.message);
      this.binaryModal.hide();
      // this.router.navigate(['/user/dashboard']);
      // this.tribesList = results.success;
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }

  submitJoinRequest(): void{
    const params = {tribe_id: this.tribeId, user_id: this.user.id};
    this.tribesService.requestJoinTribe(params).subscribe(results => {
      this.fetchTribesTopics(this.tribeId);
      this.toast.success(results.message);
      this.binaryModal.hide();
      // this.router.navigate(['/user/dashboard']);
      // this.tribesList = results.success;
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }

  option1Chosen(): void{
    this.binaryModal.hide();
    this.videoContent.hide();
    this.mediaVideo = '';
    this.imagesModal.hide();
    this.audioContent.hide();
  }

  joinTribe(): void{
    this.submitJoinTribe();
  }

  joinRequest(): void{
    this.submitJoinRequest();
  }

  submitUnJoinTribe(): void{
    const params = {tribe_id: this.tribeId, user_id: this.user.id};
    this.tribesService.unJoinTribe(params).subscribe(results => {
      this.fetchTribesTopics(this.tribeId);
      this.toast.success(results.message);
      this.binaryModal.hide();
      // this.router.navigate(['/user/dashboard']);
      // this.tribesList = results.success;
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }

  unJoinTribe(): void{
  this.submitUnJoinTribe();
  }

  playVideo(video: string): void {
    this.mediaVideo = video;
    this.mediaVideo = video.replace('/public', '')
    this.vid = document.getElementById('myVideo');
    // this.mediaSectionVideo = true;
    // this.mediaSectionImage = false;
    this.videoContent.show();
  }

  viewImage(image: string): void{
      this.mediaImage = image;
      this.mediaImage = image.replace('/public', '');
      // this.mediaSectionImage = true;
      // this.mediaSectionVideo = false;
      this.imagesModal.show();
  }

  viewAudio(audio: string): void{
    this.mediaAudio = audio;
    this.audioContent.show();
  }

  toFixUrlOfAudio(url: any) {
    if (url === undefined) {
      return ''; // Or handle this case appropriately
    }
    let urlEncode = url.split('/public').join('')
    return this.sanitizer.bypassSecurityTrustResourceUrl(urlEncode);
  }

  toFixUrlOfVideo(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
}
