import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from '../services/auth.service';
import {TribesService} from '../services/tribes.service';
import {Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges {
  public chatSection: boolean;
  public currentTribe: any = [];
  public msg: Subject<any> = new Subject();
  public msgArray: Observable<Array<any>> = new Observable<Array<any>>();
  public currentYear: number;
  constructor(public router: Router, public authService: AuthService, private tribeService: TribesService) {
    this.currentYear = new Date().getFullYear();
    const isTribeUser = window.localStorage.getItem('isTribeUser');
    if (isTribeUser === 'true') {
      // this.chatSection = !this.chatSection;
      this.showUserProfile();
      this.falseIsTribeSession();
    }
  }

  ngOnInit() {
  }

  public onChange(target: any) {
    this.msg.next(target.value);
    target.value = '';
  }

  public onMsgReceive(msg: string) { }

  ngOnChanges(changes: SimpleChanges) {
    this.chatSection = true;
    const isTribeUser = window.localStorage.getItem('isTribeUser');
    if (isTribeUser === 'true') {
      this.showUserProfile();
      this.falseIsTribeSession();
    }
  }

  showUserProfile(): void {
    this.tribeService.tribeEmitted.subscribe((data) => {
      this.currentTribe = data;
      if (this.currentTribe) {
        this.chatSection = true;
        // this.toggleChat();
      }
    });
  }

  falseIsTribeSession(): void {
    (async () => {
      await new Promise(resolve => setTimeout(() => resolve(), 500)).then(() => {
        localStorage.setItem('isTribeUser', 'false');
      });
    })();
  }
  contact() {
    this.router.navigate(['contact']);
  }
  home() {
    this.router.navigate(['/']);
  }

  toggleChat(): void {
    this.chatSection = !this.chatSection;
  }
}
