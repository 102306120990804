<div class="right_section">
  <div class="passed-levels">
    <div>
      <h3>Passed Levels</h3>
    </div>

    <table class="table table-bordered table-hover custab q-table">
      <thead>
      <tr>
        <th>Topic</th>
        <th>Total Question</th>
        <th>Level</th>
        <th>Correct Answer</th>
        <th>Points</th>
        <th>Status</th>
      </tr>
      </thead>

      <ng-container *ngIf="levelData && levelData.length > 0">
        <ng-container *ngFor="let item of levelData; let i = index">
          <ng-container *ngFor="let level of item?.level_clear_data_by_level">
          <tr *ngFor="let levels of level?.level_clear_details">
            <td>{{item?.topic_name}}</td>
            <td>{{levels.total_questions}}</td>
            <td>{{levels.level}}</td>
            <td>{{levels.correct_questions}}</td>
            <td>{{levels.points}}</td>
            <td class="text-danger">Played on <span style="font-weight: bold">{{levels.cleared_date | date }}</span></td>
          </tr>
          </ng-container>
        </ng-container>

      </ng-container>

      <div class="item-list no-items-found" *ngIf="levelData.length === 0">
        <span>No Item Found</span>
      </div>

    </table>
  </div>


</div>
