import { Component, OnInit } from '@angular/core';
import {QuestionsService} from '../../shared/services/questions.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-my-questions',
  templateUrl: './my-questions.component.html',
  styleUrls: ['./my-questions.component.css', '../../../assets/css/styles.css']
})
export class MyQuestionsComponent implements OnInit {
  public user: any = [];
  public questions: any = [];
  public totalAskedQuestions: any;
  public totalEarnedPoints: any;
  public topicsList: any = [];
  public selectedTopic: any;
  public pointCurrentTopic: any;
  public questionAskCurrentTopic: any;
  public totalAnswerExerics: any;


  constructor(private questionService: QuestionsService, private toast: ToastrService) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit(): void {
    this.fetchPoints();
    this.fetchTopics();
  }

  fetchQuestions(topic_id: number): void{
    this.questionService.getQuestions(topic_id).subscribe(results => {
      this.questions = results.topic_wise_points;
      this.pointCurrentTopic = results.topic_wise_points[0].points_at_this_topic;
      this.questionAskCurrentTopic = results.topic_wise_points[0].questions_ask_at_this_topic;
      this.totalAnswerExerics = results.topic_wise_points[0].total_answered_exercises;
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }
  

  fetchPoints(): void{
    this.questionService.getPoints().subscribe(results => {
      // this.questions = results.topic_wise_points;
      this.totalAskedQuestions = results.total_asked_questions_by_user;
      this.totalEarnedPoints = results.toal_points_earned_by_user;
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }

  fetchTopics(): void{
    this.questionService.getTopics().subscribe(results => {
      const {data} = results;
      this.topicsList = data;
    }, error => {
      this.toast.error(error.error.error_description);
    });
  }

  getQuestions(){
    if (this.selectedTopic !== 'undefined'){
        this.fetchQuestions(this.selectedTopic);
    }
  }
}
