import { Component, OnInit } from '@angular/core';
import {TribesService} from '../../shared/services/tribes.service';

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.css']
})
export class TermConditionComponent implements OnInit {

  public data: any;
  constructor(private tribesService: TribesService) { }

  ngOnInit(): void {
    this.fetchTerms();
  }

  fetchTerms(): void{
    this.tribesService.getTermsPage().subscribe(results => {
      this.data = results.data;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

}
