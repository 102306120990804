import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PasslevelRoutingModule} from './passlevel-routing.module';
import {PassLevelsComponent} from './pass-levels.component';

@NgModule({
  imports: [
    CommonModule,
    PasslevelRoutingModule
  ],
  declarations: [PassLevelsComponent]
})
export class PasslevelModule { }
