import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../shared/services/auth.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css',  '../../../assets/css/login.css']
})
export class VerificationComponent implements OnInit {

  public verificationForm: FormGroup;
  public submitted = false;
  constructor(private fb: FormBuilder, private authService: AuthService, private  router: Router, private toast: ToastrService) { }
  get f() { return this.verificationForm.controls; }
  ngOnInit(): void {
    this.loadBasicInfo();
  }

  loadBasicInfo(): void{
    this.verificationForm = this.fb.group({
      code: ['', Validators.compose([Validators.required])],
    });
  }

  submit(form): void{
    this.submitted = true;
    if (this.verificationForm.invalid) {
      return;
    }
    const emailID = window.localStorage.getItem('verificationEmail');
    const body = {email: emailID, code: form.value.code };
    this.authService.verifyCode(body).subscribe(data => {
      if(data.email_varified === 0){
        this.toast.warning(data.message, 'Warning');
      } else {
        this.toast.success(data.message, 'Success');
        this.router.navigate(['/auth/login']);
      }
      // if (data.code === '200'){
      //   this.toast.success(data.message, 'Success');
      //   this.router.navigate(['/auth/login']);
      // }

    }, (error: any) => {
      this.toast.error(error.error.error_description);
    });
  }

  verificationEmail(): void{
    const emailID = window.localStorage.getItem('verificationEmail');
    const body = {email: emailID};
    this.authService.emailVerification(body).subscribe(data => {
      if (data.code === 200){
        this.toast.success('Verification code sent successfully', 'Success');
        // this.router.navigate(['/auth/email-verification']);
      }

    }, (error: any) => {
      this.toast.error(error.error.error_description.email);
    });
  }

}
