<div class="right_section">
  <div class="questions">
    <div class="row mb-2">
      <div class="col-lg-6">
        <h3>All Media</h3>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-primary" (click)="showAddMedia()"><i class="fa fa-plus" style="margin-right: 5px"></i>
          Add Media</button>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="group-gap">
          <div class="d-block my-3">
            <div class="mb-3">
              <select id="mySelect" class="form-control" [(ngModel)]="selectedTribe" (change)="getTribe()">
                <option value="undefined" selected>Please Select Tribe</option>
                <option *ngFor="let item of tribeList" value="{{item.tribe_id}}">{{item.title}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12"></div>
    </div>
    <table class="table table-bordered table-hover custab q-table">
      <thead>

        <tr>
          <th>Topic Name</th>
          <th>Media</th>
          <th>Media Type</th>
          <!--<th>Points</th>-->
        </tr>
      </thead>

      <ng-container *ngIf="mediaList && mediaList.length > 0">
        <ng-container *ngFor="let item of mediaList; let i = index">
          <tr *ngFor="let media of item.media">
            <td>{{item.topic_name}}</td>
            <td style="cursor: pointer"><img (click)="setMedia(media)" src="assets/images/play.png"
                style="width: 50px; height: 50px" /></td>
            <!--<td *ngIf="media.type === 'mp4'"><img src="{{media.file}}">{{media.file}}</img></td>-->
            <td>{{media.type}}</td>
          </tr>
        </ng-container>

      </ng-container>
      <div class="item-list no-items-found" *ngIf="mediaList.length === 0">
        <span>No Item Found</span>
      </div>

    </table>
  </div>

</div><!--/col-3-->

<div bsModal #submitModal="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{'backdrop':'static', 'keyboard': false}">

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form class="form" [formGroup]="mediaForm" (ngSubmit)="submit(mediaForm)">
        <div class="modal-body text-center">
          <span class="text-center">
            <h2 style="font-size: 22px; color: #3D3D3D">Add Media</h2>
          </span>
          <div class="row mt-4">
            <div class="col-lg-12">
              <input multiple (change)="onFileChange($event)" type="file" class="form-control" id="imageThumbLoad"
                name="imageThumbLoad" placeholder="Title" formControlName="file" accept="*"
                [ngClass]="{ 'is-invalid': submitted && f.file.errors, '': f.file.touched && !f.file.errors }" required>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-12 col-md-2 col-xs-12">
              <div class="group-gap">
                <div class="d-block my-3">
                  <div class="mb-3">
                    <select id="mySelect" formControlName="topic_id" class="form-control" [(ngModel)]="selectedTopic"
                      (change)="getTopic()">
                      <option value="undefined" selected>Please Select topic</option>
                      <option *ngFor="let item of AllTopics" value="{{item.topic_id}}">{{item.tribe_title}}</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="submitted && f.topic_id.errors">
                      <sup>*</sup>Please select tribe
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12"></div>
          </div>
          <div class="justify-content-center" style="margin-top: 50px;margin-bottom: 20px">
            <button class="btn btn-danger" type="button" (click)="option1Chosen()" data-dismiss="modal"
              style="width: 30%;margin-right: 20px">Cancel</button>
            <button class="btn btn-success" type="submit" data-dismiss="modal" style="width: 30%">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div bsModal #imageModal="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{'backdrop':'static', 'keyboard': false}">

  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <span class="text-center">
          <img src="{{mediaImage}}" style="width: 380px; height: 300px;">
        </span>
        <div class="justify-content-center" style="margin-top: 50px;margin-bottom: 20px">
          <button class="btn btn-danger" mediaType="button" (click)="optionChosen()" data-dismiss="modal"
            style="width: 30%;margin-right: 20px">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div bsModal #videoContent="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{'backdrop':'static', 'keyboard': false}">

  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <iframe id="myVideo" width="100%" height="400" [src]="mediaVideo | newpipe" frameborder="0"
          allowfullscreen></iframe>
        <div class="justify-content-center" style="margin-top: 10px;margin-bottom: 10px">
          <button class="btn btn-danger" mediaType="button" (click)="optionChosen()" data-dismiss="modal"
            style="width: 30%;margin-right: 20px">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #audioContent="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{'backdrop':'static', 'keyboard': false}">

  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <audio controls autostart="0" autostart="false" *ngIf="mediaAudio" preload="none" style="width: 100%;">
          <source [src]="mediaAudio" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
        <div class="justify-content-center" style="margin-top: 10px;margin-bottom: 10px">
          <button class="btn btn-danger" mediaType="button" (click)="optionChosen()" data-dismiss="modal"
            style="width: 30%;margin-right: 20px">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>