import {Component, OnChanges, OnInit} from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import {AuthService} from '../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {IMenuItem, NavigationService} from '../services/navigation.service';
import {environment} from '../../../environments/environment';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['../../../assets/css/styles.css']
})

export class SidebarComponent implements OnInit, OnChanges {
  public mediaPath = environment.mediaPath;
  public imageSrc: any;
    public user: any = [];
    public nav: IMenuItem[];
    public selectedId: any;
    public userRole: any = [];
    constructor(private router: Router, public authService: AuthService, private toast: ToastrService,
                private route: ActivatedRoute, private navService: NavigationService) {
      this.navService.menuItems$.subscribe((items) => {
        this.nav = items;
        // this.selectedId = '1';
      });
      this.user = JSON.parse(localStorage.getItem('user'));
      this.userRole = JSON.parse(localStorage.getItem('userRole'));
      if (this.user.image){
        this.imageSrc = this.mediaPath + this.user.image;
        // this.imageSrc = this.mediaPath + this.authService.getUserImage();
      }
    }

    ngOnInit() {
      if (this.user.image){
        this.imageSrc = this.mediaPath + this.user.image;
        // this.imageSrc = this.mediaPath + this.authService.getUserImage();
      }
    }

  ngOnChanges(): void {
  }

  selectedMenu(item: IMenuItem){
      this.selectedId = item.id;
  }
}
