<div class="right_section">
  <div class="questions">
    <div class="row mb-2">
      <div class="col-lg-6">
      <h3>My Articles</h3>
      </div>
      <div class="col-lg-6 text-right">
      <button class="btn btn-primary" routerLink="/user/add-article">Add New</button>
      </div>
    </div>
    <table class="table table-bordered table-hover custab q-table">
      <thead>

      <tr>
        <th>ID</th>
        <th>Title</th>
        <th>Date</th>
        <th>Action</th>
        <!--<th>Points</th>-->
      </tr>
      </thead>
      <ng-container *ngIf="articleList && articleList.length > 0">
        <tr *ngFor="let item of articleList; let i = index">
          <td>{{i+1}}</td>
          <td>{{item.article_title}}</td>
          <td>{{item.created_at| baDate:'DD-MMM-YYYY ':'DD MMM YYYY'}}</td>
          <td class="text-center"><button class="btn btn-success" (click)="setArticleId(item)">Edit</button><button class="btn btn-success" (click)="showDeleteArticle(item)" style="margin-left: 5px">Delete</button> </td>
        </tr>
      </ng-container>
      <div class="item-list no-items-found" *ngIf="articleList.length === 0">
        <span>No Item Found</span>
      </div>

    </table>
  </div>

</div><!--/col-3-->

<div bsModal #submitModal="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
     [config]="{'backdrop':'static', 'keyboard': false}">

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <span class="text-center">
          <h2 style="margin-top: 77px;font-size: 22px; color: #3D3D3D">Are you sure want to delete this article?</h2>
        </span>
        <div class="justify-content-center" style="margin-top: 50px;margin-bottom: 20px">
          <button class="btn btn-danger" type="button" (click)="option1Chosen()" data-dismiss="modal" style="width: 30%;margin-right: 20px">Cancel</button>
          <button class="btn btn-success" type="button" (click)="setDeleteArticle()" data-dismiss="modal" style="width: 30%">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>
