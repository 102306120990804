import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {ArticleService} from '../../shared/services/article.service';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NoWhiteSpaceValidator} from '../../shared/custom-validator/noWhitespace.validator';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-all-media',
  templateUrl: './all-media.component.html',
  styleUrls: ['./all-media.component.css',  '../../../assets/css/styles.css']
})
export class AllMediaComponent implements OnInit {
  sanitizedUrl: SafeResourceUrl | null = null;

  public mediaList: any = [];
  public tribeList: any = [];
  public user: any = [];
  public userRole: any = [];
  public delArticleId: number;
  public AllTopics: any = [];
  public selectedTopic: any;
  public selectedTribe: any;
  public tribesList: any = [];
  public mediaForm: FormGroup;
  fileToUpload: File = null;
  public errorMessage: string = '';
  isImage: boolean;
  public submitted = false;
  files: string  []  =  [];
  @ViewChild('imageModal') imagesModal: ModalDirective;
  @ViewChild('videoContent') videoContent: ModalDirective;
  @ViewChild('audioContent') audioContent: ModalDirective;
  public mediaImage: string;
  public mediaVideo: string;
  public mediaAudio: string;
  test: string

  @ViewChild('submitModal') binaryModal: ModalDirective;
  constructor(private articleService: ArticleService, private router: Router,
              private toast: ToastrService, private fb: FormBuilder, public sanitizer: DomSanitizer) {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.userRole = JSON.parse(localStorage.getItem('userRole'));
    if (this.userRole.role !== 2 && this.userRole.is_leader !== 1) {
      this.router.navigate(['/user/dashboard']);
    }
  }

  ngOnInit(): void {
    this.loadForm();
    this.fetchTribesByLeader();
  }

  get f() { return this.mediaForm.controls; }
  loadForm(): void{
    this.mediaForm = this.fb.group({
      topic_id: new FormControl('', [
        Validators.required
      ]),
      file: new FormControl('', [
        Validators.required
      ])
    });
  }

  handleFileInput(event, files: FileList) {
    // Images only
    // const str = files('image/');
    const str = files[0].type.split('image/');
    const type = str[1];
    if (type !== 'png' && type !== 'jpg' && type !== 'jpeg' && type !== 'PNG') {
      this.isImage = false;
      this.errorMessage = 'Invalid File';
      return;
    }
    this.errorMessage = '';
    this.fileToUpload = files.item(0);
    this.mediaForm.controls['file'].setValue(this.fileToUpload);
    // this.articleForm.controls.image.setValue(this.fileToUpload);
  }
  onFileChange(event)  {
    for (var i =  0; i <  event.target.files.length; i++)  {
      this.files.push(event.target.files[i]);
    }
  }
  fetchTribeMedia(tribeId: number): void{
    this.articleService.getTribeMedia(tribeId).subscribe(results => {
      const {data} = results;
      this.mediaList = data;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  fetchTribesByLeader(): void{
    this.articleService.getTribeByLeader(this.user.id).subscribe(results => {
      const {data} = results;
      // this.tribeList.push(...data);
      this.tribeList = data;
      if (this.tribeList){
        this.setTopics(this.tribeList);
      }
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  setTopics(tribes){
    const List = [];
    tribes.forEach( tribe => {
      tribe.topics.forEach(topics => {
        List.push(topics);
      });
    });
    this.AllTopics = List;
  }

  setArticleId(item): void{
    const id = item.id;
    if (id){
      this.router.navigate(['user/update-article/' + id]);
    }
  }
  getTopic(){
    if (this.selectedTopic !== 'undefined'){
      this.selectedTopic = this.selectedTopic;
    }
  }

  getTribe(){
    if (this.selectedTribe !== 'undefined'){
      this.selectedTribe = this.selectedTribe;
      this.fetchTribeMedia(this.selectedTribe);
    }
  }
  showAddMedia(): void{
      this.binaryModal.show();
      this.formData();
    this.mediaForm.controls['topic_id'].setValue('undefined');
    this.files = [];
  }

  option1Chosen(): void{
    this.binaryModal.hide();
  }

  submit(form): void {
    this.submitted = true;
    if (this.mediaForm.invalid) {
      return;
    }
    this.formData();
    this.articleService.postMedia(this.formData()).subscribe(data => {
      if(data.error_messages){
        data.error_messages.forEach( msg =>{
          this.toast.error(msg, 'Error');
        });
      }
      if (data.status === 'success'){
        this.toast.success(data.message, 'Success');
        this.binaryModal.hide();
      } else if(data.status === 'error'){
        this.toast.error(data.message, 'Error');
      }
    }, (error: any) => {
      this.toast.error(error.message);
    });
  }

  formData() {
    const formDataUpload: FormData = new FormData();
    for  (var i =  0; i <  this.files.length; i++)  {
      formDataUpload.append("file[]",  this.files[i]);
    }
    formDataUpload.append('topic_id', this.mediaForm.controls['topic_id'].value);
    return formDataUpload;
  }

  setMedia(media) {
    const filePath = '/public';
    const fileString = media.file.toString();
    
    // Remove the '/public' segment from the file path
    const cleanFilePath = fileString.replace(filePath, '');
  
    if (media.type === 'jpg' || media.type === 'png' || media.type === 'jpeg') {
      this.mediaImage = cleanFilePath;
      this.imagesModal.show();
    } else if (media.type === 'mp4') {
      this.mediaVideo = cleanFilePath;
      this.videoContent.show();
    } else if (media.type === 'mp3') {
      this.mediaAudio = cleanFilePath;
      this.audioContent.show();
    }
  
    console.log(cleanFilePath);
  }

  optionChosen(): void{
    this.mediaVideo = '';
    this.mediaAudio = '';
    this.binaryModal.hide();
    this.videoContent.hide();
    this.imagesModal.hide();
    this.audioContent.hide()
  }
}
