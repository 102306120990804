import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {PagesModule} from './pages/pages.module';
import {AdminModule} from './admin/admin.module';
import {AuthModule} from './auth/auth.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => PagesModule,
  },
  {
    path: 'user',
    loadChildren: () => AdminModule,
  },
  {
    path: 'auth',
    loadChildren: () => AuthModule,
  },

  // { path: 'login', component: LoginComponent},
  // { path: 'register', component: RegisterPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  
})
export class AppRoutingModule { }
