<div class="right_section">
  <div class="questions">
    <div class="row mb-2">
      <div class="col-lg-6">
        <h3>Add Users</h3>
      </div>
    </div>
    <table class="table table-bordered table-hover custab q-table">
      <thead>
      <tr>
        <th>ID</th>
        <th>Image</th>
        <th>Name</th>
        <th>Email</th>
        <th class="text-center">Action</th>
      </tr>
      </thead>
      <ng-container *ngIf="tribeUsers && tribeUsers.length > 0">
        <tr *ngFor="let item of tribeUsers; let i = index">
          <td>{{i+1}}</td>
          <td *ngIf="item.image"><img src="{{mediaPath + item.image}}" class="user-image"/> </td>
          <td *ngIf="item.image === ''"><img src="assets/images/avatar_2x.png" class="user-image"/> </td>
          <td>{{item.name}}</td>
          <td>{{item.email}}</td>
          <td class="text-center"><button class="btn btn-success" (click)="addUser(item.id)"><i class="fa fa-plus"></i> Add</button> </td>
        </tr>
      </ng-container>
      <div class="item-list no-items-found" *ngIf="tribeUsers.length === 0">
        <span>No Item Found</span>
      </div>

    </table>
  </div>

</div><!--/col-3-->
