import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AddArticleComponent} from './add-article.component';


const routes: Routes = [
  { path: 'add-article', component: AddArticleComponent},
  { path: 'update-article/:id', component: AddArticleComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddArticleRoutingModule { }
