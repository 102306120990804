<!--Footer Starts-->
<footer>
    <div class="container-fluid">
        <p class="copyright text-center">
                Copyright  &copy;  {{currentDate | date: 'yyyy'}} <a id="pixinventLink" href="#">Stepin Solutions</a>, All rights reserved.
        </p>

    </div>
</footer>
<!--Footer Ends-->
