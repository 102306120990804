<!-- Sidebar Header starts -->
<!--<div class="sidebar-header">-->
    <!--<div class="logo clearfix">-->
        <!--<a class="logo-text float-left">-->
            <!--&lt;!&ndash;<div class="logo-img">&ndash;&gt;-->
                <!--&lt;!&ndash;<img src="../../../assets/img/esolution2.PNG" />&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--<span class="text align-middle">Scorai</span>-->
        <!--</a>-->
        <!--&lt;!&ndash;<a class="nav-toggle d-none d-sm-none d-md-none d-lg-block" id="sidebarToggle" href="javascript:;">&ndash;&gt;-->
            <!--&lt;!&ndash;<i class="ft-toggle-right toggle-icon" data-toggle="expanded"></i>&ndash;&gt;-->
        <!--&lt;!&ndash;</a>&ndash;&gt;-->
        <!--&lt;!&ndash;<a class="nav-close d-block d-md-block d-lg-none d-xl-none" id="sidebarClose" href="javascript:;">&ndash;&gt;-->
            <!--&lt;!&ndash;<i class="ft-x"></i>&ndash;&gt;-->
        <!--&lt;!&ndash;</a>&ndash;&gt;-->
    <!--</div>-->
<!--</div>-->
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->

<div class="sidebar">
  <div class="img_section">
    <img src="{{authService.getUserImage()}}" *ngIf="imageSrc" alt="" class="img-fluid">
    <img src="assets/images/avatar_2x.png" *ngIf="!imageSrc" alt="" class="img-fluid">
  </div>
  <h5 class="text-center name-text pt-2" *ngIf="userRole.identity_label" [innerHTML]="userRole.identity_label"></h5>
  <h3 class="text-center name-text">{{authService.getUserFullName()}}</h3>
  <h5 class="text-center name-text" *ngIf="userRole.email">{{userRole.email}}</h5>

  <div class="nav-side-menu">
    <i class="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

    <div class="menu-list">
      <ul id="menu-content" class="menu-content collapse out">
        <ng-container *ngFor="let item of nav">
        <li class="nav-item" [routerLinkActive]="['active_sideb']" [ngClass]="{'active_sideb': item.id === selectedId}" (click)="selectedMenu(item)"
            *ngIf="userRole.is_leader === 1 && item.isTribe === true">
          <a class="left-arr main-dash" routerLink={{item.state}}>
            <p>{{item.name}}</p><i class={{item.icon}}></i>
          </a>
        </li>
          <li class="nav-item" [routerLinkActive]="['active_sideb']" [ngClass]="{'active_sideb': item.id === selectedId}" (click)="selectedMenu(item)"
              *ngIf="userRole.is_leader === 0 && item.isUser === true ">
            <a class="left-arr main-dash" routerLink={{item.state}}>
              <p>{{item.name}}</p><i class={{item.icon}}></i>
            </a>
          </li>
        </ng-container>
        <!--<li class="nav-item" (click)="logout()">-->
          <!--<a class="left-arr">-->
            <!--<p>Logout </p><i class="fa fa-sign-out"></i>-->
          <!--</a>-->
        <!--</li>-->
      </ul>
    </div>
  </div>
</div>

<!-- Sidebar Content Ends -->
