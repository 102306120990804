import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RegisterPageRoutingModule} from './register-page-routing.module';
import {RegisterPageComponent} from './register-page.component';

@NgModule({
    imports: [
        CommonModule,
        RegisterPageRoutingModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [RegisterPageComponent],
})
export class RegisterPageModule {
}
