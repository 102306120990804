import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TribesService} from '../shared/services/tribes.service';
import {AuthService} from '../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ChatService, Message} from '../shared/chat/chat.service';
import {ActivatedRoute, Router} from '@angular/router';
import {scan} from 'rxjs/internal/operators';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-pages',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss', '../../assets/css/styles.css']
})
export class AdminComponent implements OnInit {
  messages: Observable<Message[]>;
  public mediaUrl = environment.mediaPath;
  @ViewChild('msgArea') msgArea: ElementRef;
  public tribesList: any = [];
  public topicClues: any = [];
  public user: any = [];
  public tribeId: number;
  public topicId: number;
  public chatSection: boolean;
  public currentTopic: any = [];
  public display: boolean;
  public strMsg: string;
  public isJoined: boolean;
  public tribeDetail: any;
  public joinStatus: string;
  public mediaType: string;
  public botMediaImage: string;
  public botMediaVideo: string;
  public botMediaAudio: string;
  public isExternal: string;
  public mediaImage: string;
  public mediaVideo: string;
  public mediaAudio: string;
  public clue: string;
  public upgradedLevel: string;
  public vid: any;
  constructor(private chat: ChatService, private tribesService: TribesService,
              private router: Router, private toast: ToastrService, private route: ActivatedRoute, public authService: AuthService) {
    this.user = JSON.parse(localStorage.getItem('userRole'));
    // if (this.user.role === 2 && this.user.is_leader === 1){
    //   this.display = false;
    // } else {
    //   this.display = true;
    // }
  }

  ngOnInit(): void {
    this.messages = this.chat.conversation.asObservable()
      .pipe(
        scan((acc, val) => {
          setTimeout(() => {
            this.msgArea.nativeElement.scrollTop = this.msgArea.nativeElement.scrollHeight;
          });
          return acc.concat(val);
        } )
      );
  }

  submitQuestion(): void{
    if (this.strMsg === '' || this.strMsg === undefined){
      return;
    } else {
      const userMessage = new Message(this.strMsg, 'user');
      this.chat.update(userMessage);
    }
    const body = {question: this.strMsg, topic_id : this.topicId};
    this.strMsg = '';
    this.chat.converse(body).subscribe(results => {
      this.clue = results.clue;
      this.upgradedLevel = results.upgrade_level;
      this.isExternal = results.external;
      this.mediaType = results.type;
      const botAnswer = results.data;
      if (this.isExternal === 'true' && this.mediaType === 'image'){
        this.botMediaImage = results.media_path;
      } else if (this.isExternal === 'false' && this.mediaType === 'image'){
        this.botMediaImage = this.mediaUrl + results.media_path;
      }

      if (this.isExternal === 'true' && this.mediaType === 'video'){
        this.botMediaVideo = results.media_path;
      } else if (this.isExternal === 'false' && this.mediaType === 'video'){
        this.botMediaVideo = this.mediaUrl + results.media_path;
      }

      if (this.isExternal === 'true' && this.mediaType === 'audio'){
        this.botMediaAudio = results.media_path;
      } else if (this.isExternal === 'false' && this.mediaType === 'audio'){
        this.botMediaAudio = this.mediaUrl + results.media_path;
      }
      const botMessage = new Message(botAnswer, 'bot' , this.botMediaImage,
        this.botMediaVideo, this.botMediaAudio, this.clue, this.upgradedLevel,
        this.mediaType, results.exercise, results.exercise_notification);
      this.chat.update(botMessage);
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }

  toggleChat(): void {
    this.display = !this.display;
  }

}
