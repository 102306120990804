import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AddTribeUsersRoutingModule} from './addtribeusers-routing.module';
import {AddTribeUserComponent} from './add-tribe-user.component';

@NgModule({
  imports: [
    CommonModule,
    AddTribeUsersRoutingModule
  ],
  declarations: [AddTribeUserComponent]
})
export class AddTribeUsersModule { }
