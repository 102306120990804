<main>
  <section class="tribes">
    <div class="container">
      <div class="tribes_head text-center">
        <h1>Tribes</h1>
      </div>
      <div *ngIf="this.user.role !== 0" (click)="fetchTribes()" class="row">
        <div class="col-md-4" *ngFor="let item of tribesList; let i = index">
          <div class="tribe-box box shadow1">
            <div class="inner">
              <h3 (click)="getTribeId(item.id)" style="cursor: pointer">{{item.title}}</h3>
              <p>Topics</p>
            </div>
            <div class="icon">
              <i class="fa fa-users"></i>
            </div>
            <a class="tribe-box-footer" (click)="getTribeId(item.id)" style="cursor: pointer">
              Tribe Detail <i class="fa fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div  *ngIf="this.user.role == 0">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a (click)="fetchTribes()" ngbNavLink> <h4>Joined Tribes</h4></a>
  
           
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-md-4" *ngFor="let item of tribesList; let i = index">
                  <div class="tribe-box box shadow1">
                    <div class="inner">
                      <h3 (click)="getTribeId(item.id)" style="cursor: pointer">{{item.title}}</h3>
                      <p>Topics</p>
                    </div>
                    <div class="icon">
                      <i class="fa fa-users"></i>
                    </div>
                    <a class="tribe-box-footer" (click)="getTribeId(item.id)" style="cursor: pointer">
                      Tribe Detail <i class="fa fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink (click)='fetchUnjoinedTribes()'> <h4>Available Tribes</h4></a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-md-4"  *ngFor="let item of unjoinedTribesList; let i = index">
                  <div class="tribe-box box shadow1">
                    <div class="inner">
                      <h3 (click)="getTribeId(item.id)" style="cursor: pointer">{{item.title}}</h3>
                      <p>Topics</p>
                    </div>
                    <div class="icon">
                      <i class="fa fa-users"></i>
                    </div>
                    <a class="tribe-box-footer" (click)="getTribeId(item.id)" style="cursor: pointer">
                      Tribe Detail <i class="fa fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
     
      
      
      
    
      
    </div>
  </section>
</main>

