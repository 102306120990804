import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { google } from 'google-maps';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {NoWhiteSpaceValidator} from '../../shared/custom-validator/noWhitespace.validator';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../../../assets/css/styles.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  public formBasic: FormGroup;
  @ViewChild('submitModal') binaryModal: ModalDirective;
  @ViewChild('gmap')  gmapElement: ElementRef;
  map: google.maps.Map;
  public submitted = false;

  constructor(private fb: FormBuilder, public authService: AuthService, private toast: ToastrService) { }

  get f() { return this.formBasic.controls; }

  ngOnInit() {
    this.loadBasicForm();
  }

  loadBasicForm(): void{
    this.formBasic = this.fb.group({
      name: new FormControl('', [
        Validators.required, NoWhiteSpaceValidator.cannotContainSpace
      ]),
      email: new FormControl('', [
        Validators.required, Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'),
        NoWhiteSpaceValidator.cannotContainSpace
      ]),
      message: new FormControl('', [
        Validators.required,
        NoWhiteSpaceValidator.cannotContainSpace
      ])
      // name: ['', Validators.compose([Validators.required])],
      // email: ['', Validators.compose([Validators.required, Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')])],
      // message: ['', Validators.compose([Validators.required])],
    });
  }

  ngAfterViewInit() {
    // const mapProp = {
    //   center: new google.maps.LatLng(50.88067, 4.292880),
    //   zoom: 15,
    //   mapTypeId: google.maps.MapTypeId.ROADMAP
    // };
    // this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }

  onSubmit(form): void{
    this.submitted = true;
    if (this.formBasic.invalid) {
      return;
    }

    this.authService.homeContactUs(form.value).subscribe((data) => {
      if (data.code === '200'){
        this.binaryModal.show();
        this.formBasic.reset();
      }
    }, error => {
      // this.toast.error(error.error.error_description.error);
      this.toast.error(error.error.message);
    });

  }

  option1Chosen(): void{
    this.binaryModal.hide();
  }

  keyPress(event: any, type: string) {
    let pattern = /^[^\s<>*!@#$%&]+$/;

    if (type === 'BLOCKSPECIALWITHSPACE') {
      pattern = /^[^\s<>*!@#$%&]+$/;
    } else if (type === 'BLOCKSPECIALONLY') {
      pattern = /^[^<>*!@#$%&]+$/;
    } else if (type === 'BLOCKSPACEONLY') {
      pattern = /^[^\s]+$/;
    } else if (type === 'NUMBERS') {
      pattern = /^\d+$/;
    } else if (type === 'DECIMAL') {
      pattern = /^(\d+\.?\d{0,9}|\.\d{1,9})$/;
      if (event.path[0].value.indexOf('.') > -1 && event.key === '.') {
        return false;
      } else if (event.path[0].value.length > 0 && event.path[0].value.indexOf('.') === -1 && event.key === '.') {
        return true;
      }
    }
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  isFieldInvalid(fieldName: string): boolean {
    const f = this.formBasic.controls[fieldName];
    return f.invalid && f.dirty && f.touched;
  }

}
