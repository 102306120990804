import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ManageTribesComponent} from './manage-tribes.component';


const routes: Routes = [
  { path: 'manage-tribes', component: ManageTribesComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageTribesRoutingModule { }
