import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TribeUsersRoutingModule} from './tribeusers-routing.module';
import {TribeUsersComponent} from './tribe-users.component';
import {ModalModule} from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    TribeUsersRoutingModule,
    ModalModule.forRoot()
  ],
  declarations: [TribeUsersComponent]
})
export class TribeUsersModule { }
