import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VerificationRoutingModule } from './verification-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {VerificationComponent} from './verification.component';

@NgModule({
    imports: [
        CommonModule,
        VerificationRoutingModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [VerificationComponent],
})
export class VerificationModule {
}
