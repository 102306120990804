import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {
	constructor(private http: HttpService, private router: Router) {

  }
  public getDashboardStats(): Observable<any> {
    const url = `dashboard/stats`;
    return this.http.get<any>(url);
  }

}
