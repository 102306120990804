import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TribeUsersComponent} from './tribe-users.component';


const routes: Routes = [
  { path: 'tribe-users/:id', component: TribeUsersComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TribeUsersRoutingModule { }
