import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Session} from '../models/StorageSessions';
import {HttpService} from './http.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ArticleService {
	constructor(private http: HttpService, private router: Router, private https: HttpClient) {
  }

  public updateUserProfile(params): Observable<any> {
    const url = `profile`;
    return this.http.put<any>(url, params);
  }

  public getArticles(): Observable<any> {
    const url = `get_articles`; //api url
    return this.http.get<any>(url);
  }
  public getTribeMedia(tribeId: number): Observable<any> {
    const url = `tribe/get/media`; //api url
    const body = { tribeId: tribeId };
    return this.http.post<any>(url, body);
  }

  public getTribeByLeader(leader_id: number): Observable<any> {
    const url = `tribe_leader/get/tribes`; //api url
    const body = { leader_id: leader_id };
    return this.http.post<any>(url, body);
  }
  public deleteArticles(art_id: number): Observable<any> {
    const url = `delete_article`; //api url
    const body = { art_id: art_id };
    return this.http.post<any>(url, body);
  }

  public getDiscussionArticles(id: number): Observable<any> {
    const url = `get_articles`; //api url
    const body = { id: id };
    return this.http.post<any>(url, body);
  }

  public getArticlesComments(article_id: number): Observable<any> {
    const url = `get_comments`; //api url
    const body = { article_id: article_id };
    return this.http.post<any>(url, body);
  }

  public postArticle(params): Observable<any> {
    // return this.https.post<any[]>(environment.apiUrl + `make_article`, params, this.http.getOptions());
    const url = 'make_article'; //api url
    return this.http.post<any>(url, params);
  }

  public postMedia(params): Observable<any> {
    // return this.https.post<any[]>(environment.apiUrl + `make_article`, params, this.http.getOptions());
    const url = 'tribe/add/media'; //api url
    return this.http.post<any>(url, params);
  }


    public updateArticle(params): Observable<any> {
    const url = 'edit_article'; //api url
    return this.http.post<any>(url, params);
  }
  public postArticleComment(params): Observable<any> {
    const url = 'post_comment'; //api url
    return this.http.post<any>(url, params);
  }


  public postLike(comment_id): Observable<any> {
    const url = 'add_like'; //api url
    const body = { comment_id: comment_id };
    return this.http.post<any>(url, body);
  }

  public deleteComment(params): Observable<any> {
    const url = 'delete_comment'; //api url
    return this.http.post<any>(url,params);
  }

  public getArticleById(id: number): Observable<any> {
    const url = `get_article`; //api url
    const body = { id: id };
    return this.http.post<any>(url, body);
  }

  public getArticlesByTribeId(tribe_id: number): Observable<any> {
    const url = `get_articles_by_tribe_id`; //api url
    const body = { tribe_id: tribe_id };
    return this.http.post<any>(url, body);
  }
}
