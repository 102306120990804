import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    active?: boolean;
    hover?: boolean;
    activeIcon?: string;
    isTribe?: boolean;
    isUser?: boolean;
}
@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor() {
    }

    defaultMenu: IMenuItem[] = [
        {
            id: '1',
            name: 'Dashboard',
            state: 'dashboard',
            icon: 'fa fa-dashboard',
            active: false,
            hover: false,
            isTribe: true,
            isUser: true

        },

      {
        id: '2',
        name: 'Tribes',
        state: '/tribes',
        icon: 'fa fa-user-circle-o',
        activeIcon: './assets/img/icons/products-active.svg',
        active: false,
        hover: false,
        isTribe: true,
        isUser: true
      },
      {
        id: '8',
        name: 'Tribes Requests',
        state: 'tribes-requests',
        icon: 'fa fa-user-circle-o',
        activeIcon: './assets/img/icons/products-active.svg',
        active: false,
        hover: false,
        isTribe: true,
        isUser: false
      },

      {
        id: '6',
        name: 'Articles',
        state: 'article-list',
        icon: 'fa fa-newspaper-o',
        activeIcon: './assets/img/icons/list.svg',
        active: false,
        hover: false,
        isTribe: true,
        isUser: false
      },
        {
            id: '3',
            name: 'My Topics',
            state: 'tribes',
            icon: 'fa fa-tasks',
            activeIcon: './assets/img/icons/list.svg',
            active: false,
            hover: false,
            isTribe: true,
            isUser: true
        },
        {
            id: '4',
            name: 'My Questions',
            state: 'questions',
            icon: 'fa fa-question',
            activeIcon: './assets/img/icons/list.svg',
            active: false,
            hover: false,
            isTribe: true,
            isUser: true
        },
      {
        id: '5',
        name: 'Discussions',
        state: '/discussion',
        icon: 'fa fa-flag',
        activeIcon: './assets/img/icons/list.svg',
        active: false,
        hover: false,
        isTribe: true,
        isUser: true
      },
      {
        id: '7',
        name: 'Manage Tribes',
        state: 'manage-tribes',
        icon: 'fa fa-users',
        active: false,
        hover: false,
        isTribe: true,
        isUser: false

      },
      {
        id: '9',
        name: 'All Media',
        state: 'all-media',
        icon: 'fa fa-picture-o',
        active: false,
        hover: false,
        isTribe: true,
        isUser: false

      }
    ];

    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();
}
