<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-dark new-custom-navbar fixed-top">
  <!-- Text Logo - Use this if you don't have a graphic logo -->
  <!-- <a class="navbar-brand logo-text page-scroll" href="index.html">Evolo</a> -->

  <!-- Image Logo -->
  <a class="navbar-brand logo-image" routerLink="/"><img src="assets/images/logo.png" alt="alternative"></a>

  <!-- Mobile Menu Toggle Button -->
  <button class="navbar-toggler" (click)="toggleState()" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-awesome fa fa-bars"></span>
    <!--<span class="navbar-toggler-awesome fa fa-times"></span>-->
  </button>
  <!-- end of mobile menu toggle button -->

  <div class="navbar-collapse menu-show" [ngClass]="{'menu-show': isIn === true , 'menu-hide': isIn === false }">
    <ul class="navbar-nav ml-auto">

      <!--<li class="nav-item">-->
        <!--<a class="nav-link page-scroll" [routerLinkActive]="['menu-active']" routerLink="/join-tribes">Join Tribes</a>-->
      <!--</li>-->

     
      <li class="nav-item" *ngIf="authService.isAuthenticated()"><a class="nav-link page-scroll" routerLink="/tribes">Tribes</a></li>
      <li class="nav-item" *ngIf="authService.isAuthenticated()">
        <a class="nav-link page-scroll" routerLink="/user/dashboard">Dashboard</a>
        
      </li>
      <li class="nav-item" *ngIf="authService.isAuthenticated()">
        <a [routerLinkActive]="['menu-active']" class="nav-link page-scroll" routerLink="/user/profile">Profile</a>
      </li>
      <!--<li class="nav-item" *ngIf="authService.isAuthenticated()">-->
        <!--<a class="nav-link page-scroll" [routerLinkActive]="['menu-active']" routerLink="/discussion">Discussions</a>-->
      <!--</li>-->

      <li class="nav-item" *ngIf="!authService.isAuthenticated()">
        <a [routerLinkActive]="['menu-active']" class="nav-link page-scroll" routerLink="/auth/login"><span class="item-text">Login</span></a>
      </li>
      <li class="nav-item" *ngIf="!authService.isAuthenticated()">
        <a [routerLinkActive]="['menu-active']" class="nav-link page-scroll" routerLink="/auth/register"><span class="item-text">Sign up</span></a>
      </li>
      <!-- <li class="nav-item"><a class="nav-link page-scroll" routerLink="/join-tribes">Join Tribe</a></li> -->

      <li class="nav-item" *ngIf="authService.isAuthenticated()" (click)="logout()" style="cursor: pointer">
        <a class="nav-link page-scroll">Logout</a>
      </li>
    </ul>
  </div>
</nav> <!-- end of navbar -->
<!-- end of navigation -->
<!-- Modal -->



<!--modal2-->

<div class="modal fade bs-" id="forgot-password" tabindex="0" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog ">
    <div class="modal-content">
      <div class="modal-header">

        <h4 class="modal-title" id="myModalLabel">Password will be sent to your email</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal">
          <fieldset>
            <div class="group">
              <input required="" class="input" type="text"><span class="highlight"></span><span class="bar"></span>
              <label class="label" for="date">Email address</label></div>


            <div class="control-group">
              <label class="control-label" for="forpassword"></label>
              <div class="controls">
                <button id="forpasswodr" name="forpassword" class="btn btn-primary btn-block">Send</button>
              </div>
            </div>
          </fieldset>
        </form>

      </div>
    </div>

  </div>
</div>
