import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PagesComponent} from './pages.component';
import {HomeModule} from './home/home.module';
import {JoinTribesModule} from './join-tribes/join-tribes.module';
import {TribesDetailModule} from './tribes-detail/tribes-detail.module';
import {ArticlesModule} from './articles/articles.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermConditionComponent } from './term-condition/term-condition.component';


const routes: Routes = [
  {  path: '', component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => HomeModule
      },
      {
        path: '',
        loadChildren: () => JoinTribesModule
      },
      {
        path: '',
        loadChildren: () => TribesDetailModule
      },
      {
        path: '',
        loadChildren: () => ArticlesModule
      },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },

      { path: 'terms-condition', component: TermConditionComponent }
      ]},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
