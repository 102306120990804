<div class="right_section">
  <div class="questions">
    <div class="row mb-2">
      <div class="col-lg-6">
        <h3>Tribe Users</h3>
      </div>
    </div>
    <table class="table table-bordered table-hover custab q-table">
      <thead>

      <tr>
        <th>ID</th>
        <th>Image</th>
        <th>Name</th>
        <th>Email</th>
        <th class="text-center">Action</th>
      </tr>
      </thead>
      <ng-container *ngIf="tribeUsers && tribeUsers.length > 0">
        <tr *ngFor="let item of tribeUsers; let i = index">
          <td>{{i+1}}</td>
          <td *ngIf="item.image"><img src="{{mediaPath + item.image}}" class="user-image"/> </td>
          <td *ngIf="item.image === ''"><img src="assets/images/avatar_2x.png" class="user-image"/> </td>
          <td>{{item.name}}</td>
          <td>{{item.email}}</td>
          <td class="text-center"><button class="btn btn-success" (click)="showModal(item.id)">Remove User</button> </td>
        </tr>
      </ng-container>
      <div class="item-list no-items-found" *ngIf="tribeUsers.length === 0">
        <span>No Item Found</span>
      </div>

    </table>
  </div>

</div><!--/col-3-->

<div bsModal #submitModal="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
     [config]="{'backdrop':'static', 'keyboard': false}">

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <span class="text-center">
          <h2 style="margin-top: 77px;font-size: 22px; color: #3D3D3D">Are you sure want to Delete this user?</h2>
        </span>
        <div class="justify-content-center" style="margin-top: 50px;margin-bottom: 20px">
          <button class="btn btn-danger" type="button" (click)="option1Chosen()" data-dismiss="modal" style="width: 30%;margin-right: 20px">Cancel</button>
          <button class="btn btn-success" type="button" (click)="deleteUser()" data-dismiss="modal" style="width: 30%">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>
