import { Component, OnInit } from '@angular/core';
import {DashboardService} from '../../shared/services/dashboard.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css', '../../../assets/css/styles.css']
})
export class DashboardComponent implements OnInit {
  public user: any = [];
  public stats: any;
  constructor(private dashboardService: DashboardService) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit() {
    this.fetchStats(this.user.id)
  }

  fetchStats(id: number): void{
    this.dashboardService.getDashboardStats().subscribe(results => {
      this.stats = results.data;
    }, error => {
      // this.toast.error(error1.error.error_description);
    });
  }


}
