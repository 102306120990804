<!--<div class="wrapper">-->
  <!--<div class="app-sidebar" data-active-color="white" data-background-color="black" data-image="assets/img/sidebar-bg/07.jpg">-->
    <!--<app-sidebar></app-sidebar>-->
    <!--<div class="sidebar-background"></div>-->
  <!--</div>-->
  <!--<app-header></app-header>-->
  <!--&lt;!&ndash;<app-navbar></app-navbar>&ndash;&gt;-->
  <!--<div class="main-panel">-->
    <!--<div class="main-content">-->
      <!--<div class="content-wrapper">-->
        <!--<div class="container-fluid">-->
          <!--<router-outlet></router-outlet>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
    <!--<app-footer></app-footer>-->
  <!--</div>-->
  <!--&lt;!&ndash;<app-notification-sidebar></app-notification-sidebar>&ndash;&gt;-->
<!--</div>-->
<app-navbar></app-navbar>
<div class="container-fluid" style="min-height: calc(100vh - 10px);">
  <div class="row profile" style="margin-top: 85px">
    <div class="col-md-3 pr-0 left-sidee">
     <app-sidebar></app-sidebar>
    </div>
    <div class="col-md-9 pl-0">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-footer></app-footer>

<div class="container">
  <div class="row">
    <div id="Smallchat">
      <div class="Layout Layout-open Layout-expand Layout-right" *ngIf="display">
        <div class="Messenger_messenger">
          <div class="Messenger_header">
            <div class="Messenger_prompt">
              <img src="assets/images/robo_icon.png" alt="" style="width: 45px; height: 45px">
              <div class="robo_chat">
                <!--<span>{{currentTopic.title}}</span>-->
                <!--<p>Level3</p>-->
              </div>
            </div> <span class="chat_close_icon" (click)="toggleChat()"><img style="width: 30px" src="assets/images/close.png"></span>
          </div>
        </div>
        <div class="msgArea" #msgArea>
          <ng-container *ngFor="let m of messages | async">
            <div [ngClass]="{ 'messages-from': m.sentBy === 'bot', 'messages-to':   m.sentBy === 'user' }">
              {{ m.content }}<br>
              <ng-container *ngIf="m.sentBy === 'bot'">
                <ng-container *ngIf="m.level !== undefined" style="font-size: 16px;font-weight: 500;">{{m.level}}<br></ng-container>
                <ng-container *ngIf="m.isExercise === 'true'" style="font-size: 14px;font-weight: 500;">{{m.exerciseQuestion}}<br></ng-container>
                <ng-container *ngIf="m.clue !== undefined"><img style="width: 25px" src="assets/images/clue.png">{{m.clue}}</ng-container>
                <ng-container *ngIf="m.type === 'image' && m.mediaImage !==undefined "><img class="image-size" src="{{m.mediaImage}}"></ng-container>
                <ng-container *ngIf="m.type === 'audio' && m.mediaAudio !==undefined">
                  <audio controls style="width: 270px;">
                    <source [src]="m.mediaAudio" type="audio/mpeg">
                  </audio></ng-container>
                <ng-container *ngIf="m.type === 'video' && m.mediaVideo !==undefined "><img class="image-size" src="assets/images/play.png"></ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <input class="form-control" placeholder="Enter your question" [(ngModel)]="strMsg" (keyup.enter)="submitQuestion()" type="text" style="position: absolute; bottom: 5px; width: 100%">
        <!--<button (click)="submitQuestion()">Send</button>-->
      </div>
      <!--===============CHAT ON BUTTON STRART===============-->
      <!--<div class="chat_on" (click)="toggleChat()"> <span class="chat_on_icon"><img src="assets/images/down_arrow.png" alt=""></span> </div>-->
      <!--===============CHAT ON BUTTON END===============-->
    </div>
  </div>
</div>
