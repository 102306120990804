import { AbstractControl, ValidationErrors } from '@angular/forms';

export class NoWhiteSpaceValidator {
    static cannotContainSpace(control: AbstractControl): ValidationErrors | null {

        let isWhitespace = (control.value?.toString() || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'cannotContainSpace': true };
    }
}
