import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {ModalDirective} from 'ngx-bootstrap/modal';
import {AuthService} from '../services/auth.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', '../../../assets/css/styles.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('loginModal') loginModal: ModalDirective;
  public menuClick: boolean;
  isIn = false;   // store state
  constructor(public router: Router, public authService: AuthService, private toast: ToastrService) { }

  ngOnInit() {
  }
  contact(): void {
    this.router.navigate(['contact']);
  }

  home(): void {
    this.router.navigate(['/']);
  }

  showLogin(): void{
    this.loginModal.show();
    // this.loginModal.hide();
  }

  showMenu(){
    this.menuClick = !this.menuClick;
  }


  toggleState() { // click handler
    const bool = this.isIn;
    this.isIn = bool === false ? true : false;
  }
  logout(){
    this.onSubmit();
  }

  onSubmit() {
    // this.authService.logout();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    const body = {user_id: user.id, access_token: token};
    this.authService.logoutUser(body).subscribe((data) => {
      this.authService.logout();
    }, error => {
      this.toast.error(error.error.error_description);
    });

  }
}
