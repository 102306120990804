
<div class="right_section">
  <div class="row mt-4">
    <div class="col-sm-3">

      <div class="text-center mt-3">
        <h4 *ngIf="!articleId">Add New Article</h4>
        <h4 *ngIf="articleId">Update Article</h4>

        <!--<img src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png" class="avatar img-circle img-thumbnail" alt="avatar">-->
        <!--<h6>Upload Image</h6>-->

      </div>

    </div>
    <div class="col-sm-9">
      <div class="tab-content">
        <div class="tab-pane active" id="home">
          <form class="form" [formGroup]="articleForm" (ngSubmit)="submit(articleForm)">
            <div class="form-group">

              <div class="col-xs-6">
                <label for="first_name"><h6 class="m-0">Title*</h6></label>
                <input type="text" class="form-control" name="article_title" id="article_title" placeholder="Title" formControlName="article_title"
                       [ngClass]="{ 'is-invalid': submitted && f.article_title.errors, '': f.article_title.touched && !f.article_title.errors }">
              </div>
            </div>

            <div class="form-group">

              <div class="col-xs-6">
                <label for="tribe_id"><h6 class="m-0">Select Tribe*</h6></label>
                <div class="group-gap">
                  <div class="d-block my-3">
                    <div class="mb-3">
                <select class="form-control" formControlName="tribe_id">
                  <option value="" selected>Please Select tribe</option>
                  <option *ngFor="let item of tribesList" value="{{item.id}}">{{item.title}}</option>
                </select>

                <!-- error block -->
                <div class="invalid-feedback" *ngIf="submitted && f.tribe_id.errors">
                  <sup>*</sup>Please select tribe
                </div>
                    </div>
                  </div>
                </div>
                <!--<input type="text" class="form-control" name="article_title" id="tribe_id" placeholder="Title" formControlName="article_title"-->
                       <!--[ngClass]="{ 'is-invalid': submitted && f.article_title.errors, '': f.article_title.touched && !f.article_title.errors }">-->
              </div>

            </div>

            <div class="form-group">

              <div class="col-xs-6">
                <label for="first_name"><h6 class="m-0">Description*</h6></label>
                <textarea rows="10" type="text" class="form-control" name="description" id="description" placeholder="Description" formControlName="description"
                          [ngClass]="{ 'is-invalid': submitted && f.description.errors, '': f.description.touched && !f.description.errors }"></textarea>
              </div>
            </div>

            <div class="form-group">
              <div class="col-xs-6">
                <label for="first_name"><h6 class="m-0">Upload Image(png, jpg, jpeg, PNG)*</h6></label>
                <input (change)="handleFileInput($event, $event.target.files)" type="file" class="form-control" id="imageThumbLoad" name="imageThumbLoad" placeholder="Title" formControlName="image" accept="image/*"
                       [ngClass]="{ 'is-invalid': submitted && f.image.errors, '': f.image.touched && !f.image.errors }" required>
              </div>
              <label class="alert-message" *ngIf="errorMessage">{{errorMessage}}</label>
            </div>
            <div class="form-group">
              <div class="col-xs-12">
                <br>
                <button class="btn btn-lg btn-success" type="submit"><i class="glyphicon glyphicon-ok-sign"></i> Submit</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>
