<main>
  <section class="tribes">
    <div class="container">
      <div class="tribes_head">
        <h1 *ngIf="tribeDetail">{{tribeDetail.title}}</h1>
      </div>
      <div class="description_section" *ngIf="tribeDetail">

        <div class="dis_set">
          <h4>Description</h4>
          <div class="join_un_btn">
            <button mediaType="button" class="btn"
              *ngIf="this.user.role === 0 && isJoined === false && isJoinRequest === 1">Request Pending</button>
            <button mediaType="button" class="btn"
              *ngIf="this.user.role === 0 && isJoined === false && isJoinRequest === 0"
              (click)="checkUserForJoin('joinRequest')">Send Join Request</button>
            <button mediaType="button" class="btn" *ngIf="this.user.role !== 0 && isJoined === false"
              (click)="checkUserForJoin('join')">Join</button>
            <button mediaType="button" class="btn" *ngIf="isJoined === true" (click)="checkUserForJoin('unJoin')">Leave
              this Tribe</button>
            <!-- <button  mediaType="button" class="btn" *ngIf="role === 1">Role 1</button>
            <button mediaType="button" class="btn" *ngIf="role === 0">Role 0</button> -->

          </div>
        </div>

        <p *ngIf="tribeDetail.description != null" [innerHtml]="tribeDetail.description"></p>
        <p *ngIf="tribeDetail.description === null">No description found</p>
      </div>

      <div class="topics_section" style="margin-bottom: 355px">
        <h6>Topics</h6>
        <div class="topic_btns">
          <button [disabled]="display == true" [ngClass]="{'button-active': item.id === topicId}" mediaType="button"
            *ngFor="let item of tribesList; let i = index" class="btn" (click)="setTribe(item)">{{item.title}}</button>
        </div>
        <div class="item-list no-items-found" *ngIf="!tribesList">
          <span>"The Topics are not yet integrated. Be patient, you will be the first to test the new topics as soon as
            they are available"</span>
        </div>
      </div>
    </div>
  </section>
  <!--<app-footer></app-footer>-->
</main>

<div class="container">
  <div class="row">
    <div id="Smallchat">
      <div class="Layout Layout-open Layout-expand Layout-right" *ngIf="display">
        <div class="Messenger_messenger">
          <div class="Messenger_header">
            <div class="Messenger_prompt">
              <img src="assets/images/robo_icon.png" alt="" style="width: 45px; height: 45px">
              <div class="robo_chat">
                <span>{{currentTopic.title}}</span>
                <!--<p>Level3</p>-->
              </div>
            </div> <span class="chat_close_icon" (click)="toggleChat()"><img style="width: 30px"
                src="assets/images/close.png"></span>
          </div>
        </div>
        <div class="msgArea" #msgArea>
          <ng-container *ngFor="let m of messages | async">
            <div *ngIf="m.content"
              [ngClass]="{ 'messages-from': m.sentBy === 'bot', 'messages-to':   m.sentBy === 'user' }">
              <span *ngIf="m.content != ' '" [innerHtml]="m.content"></span>
              <ng-container *ngIf="m.sentBy === 'bot'">
                <ng-container *ngIf="m.level !== undefined"
                  style="font-size: 16px;font-weight: 500;">{{m.level}}<br></ng-container>
                <ng-container *ngIf="m.isExercise === 'true'"
                  style="font-size: 14px;font-weight: 500;">
                  <span *ngIf="m.exerciseQuestion != ''">
                    <br>
                    <br>
                    <b>{{m.exerciseQuestion}}</b>
                    <br>
                  </span>
                </ng-container>
                <ng-container *ngIf="m.clue !== undefined"><img style="width: 25px"
                    src="assets/images/clue.png">{{m.clue}}</ng-container>
                <!-- <ng-container *ngIf="m.message !== undefined" ><div > {{m.message}}  </div></ng-container> -->
                <ng-container *ngIf="m.isExercise === 'true'"><span
                    style="font-size: 14px;font-weight: 600;">Question:</span><span [innerHTML]="m.exerciseQuestions"></span><span>?</span><br></ng-container>
                <ng-container *ngIf="m.correctAnswer && m.correctAnswer !== 'false'"><span
                    style="font-size: 14px;font-weight: 600;">Correct Answer:</span>
                  <span [innerHtml]="m.correctAnswer"></span><br></ng-container>
                <ng-container *ngIf="m.confidenceLevel && m.confidenceLevel !== 'false'"><span
                    style="font-size: 14px;font-weight: 600;">Confidence
                    Level:</span>{{m.confidenceLevel}}<br></ng-container>


                <ng-container *ngIf="m.mediaType === 'image' && m.mediaImage !==undefined "><img class="image-size"
                    [src]="m.mediaImage.split('/public').join('')" (click)="viewImage(m.mediaImage)"></ng-container>
                <ng-container *ngIf="m.mediaType === 'audio' && m.mediaAudio !==undefined">
                  <audio controls style="width: 270px;" *ngIf="m.mediaAudio">
                    <source [src]="m.mediaAudio.split('/public').join('') | safe" mediaType="audio/mpeg">
                  </audio>
                </ng-container>
                <ng-container *ngIf="m.mediaType === 'video' && m.mediaVideo !==undefined "><img class="image-size"
                    (click)="playVideo(m.mediaVideo)" src="assets/images/play.png"></ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <input #enterMsg class="form-control" autofocus placeholder="Enter your question" [(ngModel)]="strMsg"
          (keyup.enter)="submitQuestion()" mediaType="text" style="position: absolute; bottom: 5px; width: 100%">
        <!--<button (click)="submitQuestion()">Send</button>-->
      </div>
      <!--===============CHAT ON BUTTON STRART===============-->
      <!--<div class="chat_on" (click)="toggleChat()"> <span class="chat_on_icon"><img src="assets/images/down_arrow.png" alt=""></span> </div>-->
      <!--===============CHAT ON BUTTON END===============-->
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div id="MediaContainer">
      <div class="Layout Layout-open Layout-expand Layout-right" *ngIf="mediaSectionVideo">
        <div class="Messenger_messenger">
          <div class="Messenger_header">
            <div class="Messenger_prompt">
              <img src="assets/images/robo_icon.png" alt="" style="width: 45px; height: 45px">
              <div class="robo_chat">
                <!--<span>{{currentTopic.title}}</span>-->
                <!--<p>Level3</p>-->
              </div>
            </div> <span class="chat_close_icon" (click)="toggleMediaVideo()"><img style="width: 30px"
                src="assets/images/close.png"></span>
          </div>
        </div>
        <div class="msgArea" #msgArea>
          <span class="text-center">
            <ng-container *ngIf="mediaVideo">
              <iframe id="myVideo1" width="90%" height="340" [src]="mediaVideo | safe" frameborder="0"
                allowfullscreen></iframe>
            </ng-container>
          </span>
        </div>
        <!--<button (click)="submitQuestion()">Send</button>-->
      </div>
      <!--===============CHAT ON BUTTON STRART===============-->
      <!--<div class="chat_on" (click)="toggleChat()"> <span class="chat_on_icon"><img src="assets/images/down_arrow.png" alt=""></span> </div>-->
      <!--===============CHAT ON BUTTON END===============-->
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div id="MediaContainerImage">
      <div class="Layout Layout-open Layout-expand Layout-right" *ngIf="mediaSectionImage">
        <div class="Messenger_messenger">
          <div class="Messenger_header">
            <div class="Messenger_prompt">
              <img src="assets/images/robo_icon.png" alt="" style="width: 45px; height: 45px">
              <div class="robo_chat">
                <!--<span>{{currentTopic.title}}</span>-->
                <!--<p>Level3</p>-->
              </div>
            </div> <span class="chat_close_icon" (click)="toggleMediaImage()"><img style="width: 30px"
                src="assets/images/close.png"></span>
          </div>
        </div>
        <div class="msgArea" #msgArea>
          <span class="text-center">
            <img *ngIf="mediaImage" src="{{mediaImage}}" style="width: 380px; height: 300px;">
          </span>
        </div>
        <!--<button (click)="submitQuestion()">Send</button>-->
      </div>
      <!--===============CHAT ON BUTTON STRART===============-->
      <!--<div class="chat_on" (click)="toggleChat()"> <span class="chat_on_icon"><img src="assets/images/down_arrow.png" alt=""></span> </div>-->
      <!--===============CHAT ON BUTTON END===============-->
    </div>
  </div>
</div>



<div bsModal #submitModal="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{'backdrop':'static', 'keyboard': false}">

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <span class="text-center">
          <h2 *ngIf="joinStatus === 'joinRequest'" style="margin-top: 77px;font-size: 22px; color: #3D3D3D">Are you sure
            want to send join request for this tribe?</h2>
          <h2 *ngIf="joinStatus === 'join'" style="margin-top: 77px;font-size: 22px; color: #3D3D3D">Are you sure want
            to join this tribe?</h2>
          <h2 *ngIf="joinStatus === 'unJoin'" style="margin-top: 77px;font-size: 22px; color: #3D3D3D">Are you sure want
            to UnJoin this tribe?</h2>
        </span>
        <div class="justify-content-center" style="margin-top: 50px;margin-bottom: 20px">
          <button class="btn btn-danger" mediaType="button" (click)="option1Chosen()" data-dismiss="modal"
            style="width: 30%;margin-right: 20px">Cancel</button>
          <button *ngIf="joinStatus === 'joinRequest'" class="btn btn-success" mediaType="button"
            (click)="joinRequest()" data-dismiss="modal" style="width: 30%">Yes</button>
          <button *ngIf="joinStatus === 'join'" class="btn btn-success" mediaType="button" (click)="joinTribe()"
            data-dismiss="modal" style="width: 30%">Yes</button>
          <button *ngIf="joinStatus === 'unJoin'" class="btn btn-success" mediaType="button" (click)="unJoinTribe()"
            data-dismiss="modal" style="width: 30%">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #imageModal="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">

  <div class="modal-dialog modal-md" role="document" style="max-width: 800px">
    <div class="modal-content">
      <div class="modal-body text-center">
        <span class="text-center">
          <img src="{{mediaImage}}" style="width: 100%; object-fit: contain;">
        </span>
      </div>
    </div>
  </div>
</div>


<div bsModal #videoContent="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{'backdrop':'static', 'keyboard': false}">

  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <!--<video id="myVideo" width="100%" controls>-->
        <!--<source src="assets/images/mov_bbb.mp4" mediaType="video/mp4">-->
        <!--Your browser does not support the video tag.-->
        <!--</video>-->
        <iframe id="myVideo" width="100%" height="400" [src]="mediaVideo | safe" frameborder="0"
          allowfullscreen></iframe>

        <div class="justify-content-center" style="margin-top: 10px;margin-bottom: 10px">
          <button class="btn btn-danger" mediaType="button" (click)="option1Chosen()" data-dismiss="modal"
            style="width: 30%;margin-right: 20px">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div bsModal #audioContent="bs-modal" class="modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{'backdrop':'static', 'keyboard': false}">

  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <audio controls *ngIf="mediaAudio">
          <!-- Use safe pipe to sanitize URL and ensure safe resource loading -->
          <source [src]="mediaAudio.split('/public').join('') | safe" type="audio/mpeg">
        </audio>
        <div class="justify-content-center" style="margin-top: 10px;margin-bottom: 10px">
          <button class="btn btn-danger" mediaType="button" (click)="option1Chosen()" data-dismiss="modal"
            style="width: 30%;margin-right: 20px">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>