import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AddTribeUserComponent} from './add-tribe-user.component';


const routes: Routes = [
  { path: 'add-tribe-users/:id', component: AddTribeUserComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddTribeUsersRoutingModule { }
